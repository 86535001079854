import axios from "axios";
import { toast } from 'react-toastify';
import { apiURL } from "../helpers/functions";
import { errorAction } from "../helpers/functions";
import { dataHeaders } from "../helpers/functions";
import { removeToken } from "../helpers/cookies";
import {
    errorLoggingIn,
    errorConsultingProfileData,
    errorConsultingVersionAPP,
    errorSendingEmailPasswordReset,
    errorConfirmingTokenForPasswordReset,
    errorResettingPassword
} from "../constant/messages";

export const loginAction = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiURL()}/api/login`, {
            email: data.email,
            password: data.password
        })
            .then((res) => {
                if (!res.data.meta.success) {
                    // toast.error(res.data.data[0], {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    // });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch(() => {
                reject(errorLoggingIn);
            });
    });
}

export const meAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/me`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorConsultingProfileData));
            });
    })
}

export const logoutAction = (data) => {
    axios.delete(`${apiURL()}/api/logout`, dataHeaders())
        .then(() => {
            removeToken();
            window.location.replace('/login')
            sessionStorage.setItem('logout', true)
            localStorage.removeItem('modalModeOffLine')
        })
        .catch(() => {
            removeToken();
            window.location.replace('/login')
            sessionStorage.setItem('logout', true)
            localStorage.removeItem('modalModeOffLine')
        });
}

export const validateVersionAppAction = () => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiURL()}/api/app/validate`,
            {
                "version": parseInt(process.env.REACT_APP_VERSION_ANDROID),
                //"version": 1,
                "os": "Android"
            }
        )
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(false);
                    removeToken()
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                errorAction(error, errorConsultingVersionAPP)
                reject(null);
            });
    })
}

export const passwordResetAction = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiURL()}/api/password-reset`, data)
            .then((res) => {
                if (!res.data.meta.success) {
                    // toast.error(res.data.data[0], {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    // });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject((error.response && error.response.status === 422 && error.response.data.meta.errors.email[0]) ?
                    error.response.data.meta.errors.email[0] :
                    errorSendingEmailPasswordReset
                );
            });
    });
}

export const tokenConfirmPasswordAction = (token, callback) => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/password-confirm/${token}`)
            .then((res) => {
                if (!res.data.meta.success) {
                    // toast.error(res.data.data[0], {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    // });
                    callback()
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch(() => {
                toast.error(errorConfirmingTokenForPasswordReset, {
                    position: "top-right",
                    autoClose: 5000,
                });
            });
    });
}

export const passwordConfirmAction = (data) => {
    return new Promise((resolve, reject) => {
        axios.put(`${apiURL()}/api/password-confirm/${data.token}`, { password: data.password })
            .then((res) => {
                if (!res.data.meta.success) {
                    // toast.error(res.data.data[0], {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    // });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch(() => {
                reject(errorResettingPassword);
            });
    });
}

export const heartBeatAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/heartbeat`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    reject(null);
                } else {
                    resolve(res);
                }
            })
            .catch(() => {
                reject(null);
            });
    })
}