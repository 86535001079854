import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Table
} from 'reactstrap'
import { Accordion } from 'react-bootstrap';
import Contact from "../others/Contact";
import { useSelector } from "react-redux";


const ListDetails = ({ data, loading, opportunities, registers }) => {
    const userConnected = useSelector(state => state.authState.connected);
    const [isOpen, setIsOpen] = useState(null);
    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

    const [isOpenRegisters, setIsOpenRegisters] = useState(null);
    const toggleRegisters = (id) => (isOpenRegisters === id ? setIsOpenRegisters(null) : setIsOpenRegisters(id));
    
    return (
        <Container fluid="lg">
            {
                loading ?
                    <Row>
                        <Col>
                            <div className="loader-box">
                                <div className="loader-9"></div>
                            </div>
                        </Col>
                    </Row>
                    :
                    <>
                        {
                            data.length && data.map((detail, key) => {
                                return (
                                    ((detail.value && detail.show) || detail.object === 'days_without_contact') ?
                                        <Row key={key}>
                                            <Col
                                                style={{ background: 'lightgrey' }}
                                                className={'p-2 border ' + (detail.object === 'phone' ? 'd-flex align-items-center' : '')}
                                                xs="5"
                                            >
                                                <h6 className='m-0'>{detail.label}:</h6>
                                            </Col>
                                            <Col
                                                xs="7"
                                                className={'p-2 border ' + (detail.object === 'phone' ? 'contact-owner' : '')}
                                            >
                                                {detail.value}
                                                {
                                                    detail.object === 'phone' && (
                                                        <Contact phone={detail.value} />
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        :
                                        null
                                )
                            })
                        }
                        {
                            opportunities ?
                                opportunities.length ?
                                    <Row>
                                        <Col className="p-0">
                                            <Accordion defaultActiveKey="0">
                                                <CardBody className="py-2 px-0">
                                                    <div className="default-according" id="accordion">
                                                        <Card>
                                                            <CardHeader role="button" className="px-0" onClick={() => toggle(1)} >
                                                                <Row className="px-2 align-items-center">
                                                                    <Col sm="12" md="6" className="text-left">
                                                                        <h6 className="mb-0">
                                                                            Oportunidades: <span className="digits">{opportunities.length}</span>
                                                                        </h6>
                                                                    </Col>
                                                                    <Col
                                                                        sm="12"
                                                                        md="4"
                                                                        lg="3"
                                                                        className="text-right offset-0 offset-md-2 offset-lg-3 pt-2 pt-md-0"
                                                                    >
                                                                        <a
                                                                            style={{ backgroundColor: '#8b8b8b', color: 'white' }}
                                                                            className="btn col-12"
                                                                            role="button"
                                                                            aria-pressed="true"
                                                                            color="secondary"
                                                                            data-cy='button-show-opportunities-0000'
                                                                        >
                                                                            {isOpen ? "Ocultar" : "Mostrar"}
                                                                        </a>

                                                                    </Col>
                                                                </Row>
                                                            </CardHeader>
                                                            <Collapse isOpen={isOpen === 1}>
                                                                <CardBody className="px-4 py-2">
                                                                    {
                                                                        opportunities.map((opportunitiy, key) => {
                                                                            return (
                                                                                <Row className="mb-4" key={key}>
                                                                                    <Col
                                                                                        style={{ background: 'lightgrey' }}
                                                                                        className='p-2 border'
                                                                                        xs="5"
                                                                                    >
                                                                                        <h6 className='m-0'>Nombre:</h6>
                                                                                    </Col>
                                                                                    <Col xs="7" className='p-2 border'>{opportunitiy.name}</Col>
                                                                                    <Col
                                                                                        style={{ background: 'lightgrey' }}
                                                                                        className='p-2 border'
                                                                                        xs="5"
                                                                                    >
                                                                                        <h6 className='m-0'>Etapa:</h6>
                                                                                    </Col>
                                                                                    <Col xs="7" className='p-2 border'>{opportunitiy.stage}</Col>
                                                                                    <Col
                                                                                        style={{ background: 'lightgrey' }}
                                                                                        className='p-2 border'
                                                                                        xs="5"
                                                                                    >
                                                                                        <h6 className='m-0'>Responsable:</h6>
                                                                                    </Col>
                                                                                    <Col xs="7" className='p-2 border'>{opportunitiy.owner.name}</Col>
                                                                                    <Col
                                                                                        style={{ background: 'lightgrey' }}
                                                                                        className='p-2 border'
                                                                                        xs="5"
                                                                                    >
                                                                                        <h6 className='m-0'>Fecha creación:</h6>
                                                                                    </Col>
                                                                                    <Col xs="7" className='p-2 border'>{opportunitiy.created_at}</Col>
                                                                                    {
                                                                                        (opportunitiy.tasks && opportunitiy.tasks.length) ?
                                                                                            <>
                                                                                                <Col xs="12" className="border-bottom mt-4">
                                                                                                    <h6>Actividades: {opportunitiy.tasks.length}</h6>
                                                                                                </Col>
                                                                                                <Col xs="12">
                                                                                                    <Table striped responsive data-cy="table-tasks-opportunities">
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                opportunitiy.tasks.map((task, key) => {
                                                                                                                    return (
                                                                                                                        <tr key={key}>
                                                                                                                            <td style={{ width: '20%' }}>{task.date}</td>
                                                                                                                            <td style={{ width: '20%' }}>{task.status}</td>
                                                                                                                            <td style={{ width: '20%' }}>{task.subject}</td>
                                                                                                                            <td style={{ width: '40%' }}>{task.description}</td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                </Col>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </Row>
                                                                            )
                                                                        })
                                                                    }
                                                                </CardBody>
                                                            </Collapse>
                                                        </Card>
                                                    </div>
                                                </CardBody>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col className="mt-4 ml-n3">
                                            <h6>Este inmueble no tiene oportunidades</h6>
                                        </Col>
                                    </Row>
                                :
                                null

                        }
                        {
                            registers ?
                                registers.length ?
                                    <Row>
                                        <Col className="p-0">
                                            <Accordion defaultActiveKey="0">
                                                <CardBody className="py-2 px-0">
                                                    <div className="default-according" id="accordion">
                                                        <Card>
                                                            <CardHeader role="button" className="px-0" onClick={() => toggleRegisters(1)} >
                                                                <Row className="px-2 align-items-center">
                                                                    <Col sm="12" md="6" className="text-left">
                                                                        <h6 className="mb-0">
                                                                            Contactos: <span className="digits">{registers.length}</span>
                                                                        </h6>
                                                                    </Col>
                                                                    <Col
                                                                        sm="12"
                                                                        md="4"
                                                                        lg="3"
                                                                        className="text-right offset-0 offset-md-2 offset-lg-3 pt-2 pt-md-0"
                                                                    >
                                                                        <a
                                                                            style={{ backgroundColor: '#8b8b8b', color: 'white' }}
                                                                            className="btn col-12"
                                                                            role="button"
                                                                            aria-pressed="true"
                                                                            color="secondary"
                                                                            data-cy='button-show-registers-0000'
                                                                        >
                                                                            {isOpenRegisters ? "Ocultar" : "Mostrar"}
                                                                        </a>

                                                                    </Col>
                                                                </Row>
                                                            </CardHeader>
                                                            <Collapse isOpen={isOpenRegisters === 1}>
                                                                <CardBody className="px-2 py-2">
                                                                    <Table striped responsive data-cy="table-results-for-day-0000">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: '25%' }} scope="col">Fecha.</th>
                                                                                <th style={{ width: '75%' }} scope="col">Mensaje</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                registers.map((register, key) => {
                                                                                    return (
                                                                                        <tr key={key}>
                                                                                            <td>{register.created_at}</td>
                                                                                            <td>{register.body}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </CardBody>
                                                            </Collapse>
                                                        </Card>
                                                    </div>
                                                </CardBody>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col className="mt-4 ml-n3">
                                            <h6>Este inmueble no tiene registros</h6>
                                        </Col>
                                    </Row>
                                :
                                null

                        }
                    </>
            }
        </Container>
    )
};

export default ListDetails