import cookie from 'react-cookies';

export const setToken = (token) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('authToken', token, {
    path: "/",
    expires: expires
  });
}

export const setUser = (data) => {
  cookie.save('user', data, {
    path: "/",
  });
}

export const getToken = () => cookie.load('authToken', true) !== undefined ? cookie.load('authToken') : '';
export const removeToken = () => cookie.remove("authToken", { path: "/" });

export const getUser = () => cookie.load('user', true) !== undefined ? cookie.load('user') : '';
export const removeUser = () => cookie.remove("user", { path: "/" });