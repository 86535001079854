//LOGIN
export const tokenExpired = 'La sesión ha expirado'
export const errorLoggingIn = 'No se ha podido iniciar sesión'
export const errorConsultingProfileData = 'No se han podido obtener los datos del perfil'
export const errorConsultingVersionAPP = 'No se ha podido obtener la versión de la APP'
export const errorSendingEmailPasswordReset = 'No se ha podido enviar el email para restablecer la contraseña'
export const errorConfirmingTokenForPasswordReset = 'No se ha podido confirmar la petición para restablecer la contraseña'
export const errorResettingPassword = 'No se ha podido restablecer la contraseña'
export const thePasswordHasBeenChangedSuccessfully = 'La contraseña se ha cambiado correctamente.'
export const weHaveSentYouALinkFromWhereToChangeYourPassword = 'Te hemos enviado un enlace desde donde cambiar tu contraseña'
export const pleaseTryAgainLater = 'Por favor, inténtalo más tarde'

//HOME
export const errorConsultingLastRecords = 'No se han podido obtener los últimos registros de KPI de captación'
export const errorConsultingLastRecordsGraph = 'No se han podido obtener los últimos registros para mostrar el gráfico'
export const errorConsultingTasks = 'No se han podido obtener las tareas de hoy'

//ADDRESS
export const errorConsultingTheStreets = 'No se han podido obtener las calles'
export const errorConsultingTheStreetNumbers = 'No se han podido obtener los números de la calle'

//PROPERTY
export const errorConsultingTheProperties = 'No se han podido obtener los inmuebles'
export const successfullyModifiedProperty = 'Inmueble modificado correctamente'
export const errorUpdatingTheProperty = 'No se ha podido actualizar el inmueble'
export const errorConsultingTheOpportunities = 'No se han podido obtener las oportunidades del inmueble'
export const errorQueryingLogData = 'No se han podido obtener los registros'
export const successfullyRegisteredOwner = 'Propietario registrado correctamente'
export const failedToGetResults = '¡No se han podido obtener los resultados!'
export const thereAreNoAssociatedRecords = '¡No se han podido obtener registros asociados!'

//TASKS
export const thereAreNotTasks = '¡No tienes tareas registradas en el CRM para hoy!'

//DAY ZONE
export const errorConsultingTheDays = 'No se han podido obtener los días'

//OPE
export const opeCreatedSuccessfully = 'OPE creada correctamente'
export const errorRegisteringTheOpe = 'No se ha podido crear la OPE'
export const errorConsultingThePropertiesWithActiveOpe = 'No se han podido obtener los inmuebles con OPE activa'

//OPC
export const errorConsultingThePropertiesWithActiveOpc = 'No se han podido obtener los inmuebles con OPC activa'

//OFFLINE
export const offline = 'No tienes conexión'
export const haveConnectionAgain = 'Se ha recuperado la conexión'
export const errorHeartBeat = 'No se ha podido validar la conexión de la aplicación'
export const pleaseTryLater = 'por favor, inténtalo más tarde'
export const recoveredDataOffline = 'pero se han recuperado los últimos datos disponibles en tu dispositivo'
export const pendingChanges = '¡Todos los cambios pendientes se han registrado correctamente!'

//PENDING TASKS
export const errorConsultingPendingTasks = 'No se han podido obtener las tareas pendientes'

//PROPERTIES WITH ACTIVE ORDER
export const errorConsultingPropertiesWithActiveOrder = 'No se han podido obtener los inmuebles con encargo'

//ACTIONS MODE OFFLINE
export const LOADED = 'loaded'
export const NOTLOADED = 'notloaded'
export const SUCCESS = 'success'
export const ERROR = 'error'