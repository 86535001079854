import React, { useState, useEffect } from 'react';
import { Container, FormGroup, Input, Label, Button, FormFeedback } from 'reactstrap'
import { Link } from 'react-router-dom'
import { validateEmail, encryptDataUSer, decryptDataUSer } from '../helpers/functions';
import { loginAction } from '../api/login';
import { setSignIn } from '../redux/auth/action';
import { useDispatch } from 'react-redux'
import { setToken, setUser, removeUser, getUser } from '../helpers/cookies';
import { ToastContainer } from 'react-toastify'
import CardContainerNoAuth from './CardContainerNoAuth';
import { useError } from '../hooks/ErrorProvider';

const Logins = (props) => {
  const { stateError, setStateError } = useError()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [rememberSession, setRememberSession] = useState(false)
  const [email, setEmail] = useState({
    value: "",
    error: false,
    messageError: ''
  });
  const [password, setPassword] = useState({
    value: "",
    error: false,
    messageError: 'Debe introducir la contraseña'
  });

  const [togglePassword, setTogglePassword] = useState(false)

  useEffect(() => {
    if (getUser()) {
      setRememberSession(true)
      setEmail({ ...email, value: decryptDataUSer(getUser()).email })
      if (!sessionStorage.getItem('logout')) {
        loginAction(decryptDataUSer(getUser()))
          .then((res) => {
            setToken(res.data.data.access_token)
            dispatch(setSignIn(res.data.data))
            window.location.href = `/`
          })
          .catch((res) => { setStateError({ ...stateError, type: 'danger', message: res, show: true }) })
      }
    }
    setTimeout(function () {
      sessionStorage.removeItem('logout');
    }, 3000);
    document.addEventListener('keydown', handlePressDownEnter);
  }, []);

  const handlePressDownEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      let emailInput = document.getElementById('email').value
      let passwordInput = document.getElementById('password').value
      let rememberSessionCheck = document.getElementById('rememberSession').checked
      login({ email: emailInput, password: passwordInput, rememberSession: rememberSessionCheck });
    }
  }

  const validate = (data) => {
    let acum = "";

    if (data.email === '') {
      setEmail({ ...email, error: true, messageError: 'Debe introducir el email' })
      acum = 1;
    }
    if (data.email !== '' && !validateEmail(data.email)) {
      setEmail({ ...email, error: true, messageError: 'Email incorrecto' })
      acum = 1;
    }
    if (data.password === '') {
      setPassword({ ...password, error: true })
      acum = 1;
    }
    if (acum > 0) {
      return false;
    }
    return true;
  }

  const login = (data) => {
    const isValid = validate(data);
    if (isValid) {
      setLoading(true)
      loginAction({ email: data.email, password: data.password })
        .then((res) => {
          setToken(res.data.data.access_token)
          if (data.rememberSession) {
            setUser(encryptDataUSer({ email: data.email, password: data.password }))
          } else {
            removeUser()
          }
          setStateError({ ...stateError, type: '', message: '', show: false })
          dispatch(setSignIn(res.data.data))
          localStorage.setItem("modalModeOffLine", true)
          window.location.href = `/`
          // setLoading(false)
        })
        .catch((res) => {
          setLoading(false)
          setStateError({ ...stateError, type: 'danger', message: res, show: true })
        })
    }
  }

  const rememberUser = (e) => {
    if (!e.target.checked) {
      removeUser()
    }
    setRememberSession(e.target.checked)
  }

  return (
    <Container fluid={true} className="p-0" data-cy="login-container-0000">
      <ToastContainer data-cy="flash-message-login" />
      <CardContainerNoAuth>
        <>
          <FormGroup>
            <Label className="col-form-label">Email</Label>
            <Input
              className="form-control"
              type="email"
              error='true'
              onChange={e => setEmail({ ...email, value: e.target.value, error: false })}
              value={email.value}
              id="email"
              invalid={email.error}
              data-cy="input-email-login-0000"
            />
            <FormFeedback data-cy="error-input-email-login-0000">
              {
                email.error ? email.messageError : ''
              }
            </FormFeedback>
          </FormGroup>
          <FormGroup className='position-relative'>
            <Label className="col-form-label">Contraseña</Label>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              onChange={e => setPassword({ ...password, value: e.target.value, error: false })}
              value={password.value}
              id="password"
              required=""
              invalid={password.error}
              data-cy="input-password-login-0000"
            />
            <FormFeedback data-cy="error-input-password-login-0000">
              {
                password.error ? password.messageError : ''
              }
            </FormFeedback>
            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
          </FormGroup>
          <div className="form-group mb-0">
            <div className="checkbox ml-3">
              <div className="checkbox">
                <Input
                  id="rememberSession"
                  type="checkbox"
                  checked={rememberSession}
                  onChange={(e) => rememberUser(e)}
                  data-cy="input-chekbox-remember-0000"
                />
                <Label
                  className="text-muted"
                  for="rememberSession"
                  data-cy="label-chekbox-remember-0000"
                >
                  Guardar datos de acceso
                </Label>
              </div>
            </div>
            <div className='text-left'>
              <Link
                to='/password-reset'
                data-cy="reset-password-0000"
              >
                Restablecer contraseña
              </Link>
            </div>
            <hr />
            <Button
              color="primary"
              className="col-12"
              onClick={() => login({ email: email.value, password: password.value, rememberSession: rememberSession })}
              data-cy="button-login-0000"
              disabled={loading}
            >
              {
                loading ?
                  <div className="loader-box" style={{ height: 'auto' }}>
                    <div
                      className="loader-2"
                      style={{ height: '25px', width: '25px', borderLeftColor: 'white', borderRightColor: 'white' }}
                    />
                  </div>
                  :
                  'Iniciar sesión'
              }
            </Button>
          </div>
        </>
      </CardContainerNoAuth>
    </Container>
  );
}

// const mapDispatchToProps = dispatch => ({
//   signIn: (data) => dispatch(signIn(data)),
// });

export default Logins;