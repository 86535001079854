import React, { } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    FormGroup,
    Label
} from 'reactstrap'
import Select from 'react-select';
import {
    paylodadEndPointSearchProperty,
    paylodadCleanEndPointSearchProperty,
    saveRequestSearch,
    existModeOffLine
} from '../../../helpers/functions';

const ModalFilters = ({ state, setState, filterBasics, action, module }) => {

    const dataOccupedBy = [
        {
            label: 'Todos',
            value: 'todos'
        },
        {
            label: 'Propietario',
            value: 'Propietario'
        },
        {
            label: 'Inquilino',
            value: 'Inquilino'
        },
        {
            label: 'Pendiente',
            value: 'Pendiente'
        },
        {
            label: 'Ocupado',
            value: 'Ocupado'
        },
        {
            label: 'Vacacional',
            value: 'Vacacional'
        },
        {
            label: 'Vacío',
            value: 'Vacío'
        }
    ]

    const dataContacted = [
        {
            label: 'Todos',
            value: 'todos'
        },
        {
            label: 'No contactado',
            value: '0'
        },
        {
            label: 'Menos de 30 días',
            value: '30'
        },
        {
            label: 'Entre 30 días y 60 días',
            value: '30_60'
        },
        {
            label: 'Más de 60 días',
            value: '60'
        }
    ]

    const dataStatus = [
        {
            label: 'Todos',
            value: 'todos'
        },
        {
            label: 'Disponible venta',
            value: 'Disponible venta'
        },
        {
            label: 'Disponible alquiler',
            value: 'Disponible alquiler'
        },
        {
            label: 'Retirado',
            value: 'Retirado'
        },
        {
            label: 'Captación',
            value: 'Captación'
        },
        {
            label: 'Reservado',
            value: 'Reservado'
        }
    ]

    const dataOpeActive = [
        {
            label: 'Todos',
            value: 'todos'
        },
        {
            label: 'Con OPE activa',
            value: true
        },
        {
            label: 'Sin OPE activa',
            value: false
        }
    ]

    const dataStageOpc = [
        {
            label: 'Todas',
            value: 'todos'
        },
        {
            label: 'Pendiente de valorar',
            value: 'Pendiente de valorar'
        },
        {
            label: 'Valorada',
            value: 'Valorada'
        }
    ]

    const handleSelect = (value, name) => {
        setState(prev => ({ ...prev, [name]: value }))
        if (name === 'status' && value.value !== 'Captación') {
            setState(prev => ({ ...prev, stage_opc: { label: 'Todas', value: 'todos' } }))
        }
    }

    const closeModalFilters = (close) => {
        setState(prev => ({
            ...prev,
            // occupied_by: { label: 'Todos', value: 'todos' },
            // contacted: { label: 'Todos', value: 'todos' },
            // status: { label: 'Todos', value: 'todos' },
            modalFilters: close ? false : state.modalFilters
        }))
    }

    const searchMoreFilters = () => {
        if (paylodadEndPointSearchProperty(state, filterBasics).filtersOthers > 0) {
            setState(prev => ({ ...prev, loadingTable: true, loadingModal: true }))
            action(paylodadEndPointSearchProperty(state, filterBasics).payload)
                .then((res) => {
                    setState(prev => ({
                        ...prev,
                        data: res.data.data.properties,
                        loadingTable: false,
                        loadingModal: false,
                        modalFilters: false
                    }))
                    saveLocalStorageMoraFilters(res.data.data.properties)
                })
                .catch((error) => {
                    if (error && error.data) {
                        setState(prev => ({
                            ...prev,
                            data: existModeOffLine() ? error.data : error.data.data,
                            loadingTable: false,
                            loadingModal: false,
                            modalFilters: false,
                            errorMessage: error.message
                        }))
                    } else {
                        setState(prev => ({
                            ...prev,
                            data: null,
                            loadingTable: false,
                            loadingModal: false,
                            modalFilters: false,
                            errorMessage: error.message || ''
                        }))
                    }
                })
        } else {
            closeModalFilters(true)
        }
    }

    const cleanFilters = () => {
        setState(prev => ({ ...prev, loadingTable: true, loadingModal: true }))
        action(paylodadCleanEndPointSearchProperty(state, filterBasics))
            .then((res) => {
                setState(prev => ({
                    ...prev,
                    data: res.data.data.properties,
                    loadingTable: false,
                    loadingModal: false,
                    modalFilters: false,
                    occupied_by: { label: 'Todos', value: 'todos' },
                    contacted: { label: 'Todos', value: 'todos' },
                    status: { label: 'Todos', value: 'todos' },
                    stage_opc: { label: 'Todas', value: 'todos' },
                    has_active_ope: { label: 'Todos', value: 'todos' },
                }))
                saveLocalStorageMoraFilters(res.data.data.properties)
            })
            .catch((error) => {
                if (error && error.data) {
                    setState(prev => ({
                        ...prev,
                        data: existModeOffLine() ? error.data : error.data.data,
                        loadingTable: false,
                        loadingModal: false,
                        modalFilters: false,
                        errorMessage: error.message,
                        occupied_by: { label: 'Todos', value: 'todos' },
                        contacted: { label: 'Todos', value: 'todos' },
                        status: { label: 'Todos', value: 'todos' },
                        stage_opc: { label: 'Todas', value: 'todos' },
                        has_active_ope: { label: 'Todos', value: 'todos' },
                    }))
                } else {
                    setState(prev => ({
                        ...prev,
                        data: null,
                        loadingTable: false,
                        loadingModal: false,
                        modalFilters: false,
                        errorMessage: error.message || ''
                    }))
                }
            })
    }


    const saveLocalStorageMoraFilters = (data) => {
        let dataLocalStorage = {
            params: paylodadEndPointSearchProperty(state, filterBasics).payload,
            data: data,
        }
        if (module === 'day-zone') {
            dataLocalStorage.streets = state.dataSelectStreets
        } else {
            dataLocalStorage.numbers = state.dataSelectNumbers
        }

        saveRequestSearch(
            module,
            dataLocalStorage
        )
    }
    
    return (
        <Modal isOpen={state.modalFilters} scrollable size='md' data-cy="modal-filters-0000">
            <ModalHeader toggle={!state.loadingModal ? () => setState(prev => ({ ...prev, modalFilters: false })) : () => { }} >
                Filtros
            </ModalHeader>
            <ModalBody style={{ height: '40vh' }}>
                <Row data-cy="row-more-filters-search-day-zone-0000">
                    {
                        state.loadingModal ?
                            <Col>
                                <div className="loader-box">
                                    <div className="loader-9"></div>
                                </div>
                            </Col>
                            :
                            <>
                                <Col sm="12" >
                                    <FormGroup row>
                                        <Label sm={3} htmlFor="occupied_by">Ocupado por</Label>
                                        <Col sm={9}>
                                            <Select
                                                value={state.occupied_by}
                                                onChange={(e) => handleSelect(e, 'occupied_by')}
                                                options={dataOccupedBy}
                                                placeholder='Seleccione...'
                                                isSearchable={true}
                                                // isClearable={true}
                                                data-cy="select-0000"
                                                inputId='filterOccupiedBy'
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" >
                                    <FormGroup row>
                                        <Label sm={3} htmlFor="contacted">Contactado</Label>
                                        <Col sm={9}>
                                            <Select
                                                value={state.contacted}
                                                onChange={(e) => handleSelect(e, 'contacted')}
                                                options={dataContacted}
                                                placeholder='Seleccione...'
                                                isSearchable={true}
                                                // isClearable={true}
                                                data-cy="select-0000"
                                                inputId='filterContacted'
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" >
                                    <FormGroup row>
                                        <Label sm={3} htmlFor="status">Estado</Label>
                                        <Col sm={9}>
                                            <Select
                                                value={state.status}
                                                onChange={(e) => handleSelect(e, 'status')}
                                                options={dataStatus}
                                                placeholder='Seleccione...'
                                                isSearchable={true}
                                                data-cy="select-0000"
                                                inputId='filterStatus'
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                {
                                    state.status.value === 'Captación' && (
                                        <Col sm="12" >
                                            <FormGroup row>
                                                <Label sm={3} htmlFor="status">Etapa OPC</Label>
                                                <Col sm={9}>
                                                    <Select
                                                        value={state.stage_opc}
                                                        onChange={(e) => handleSelect(e, 'stage_opc')}
                                                        options={dataStageOpc}
                                                        placeholder='Seleccione...'
                                                        isSearchable={true}
                                                        data-cy="select-0000"
                                                        inputId='filterStageOpc'
                                                        menuPosition="fixed"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    )
                                }
                                <Col sm="12" >
                                    <FormGroup row>
                                        <Label sm={3} htmlFor="has_active_ope">OPE activa</Label>
                                        <Col sm={9}>
                                            <Select
                                                value={state.has_active_ope}
                                                onChange={(e) => handleSelect(e, 'has_active_ope')}
                                                options={dataOpeActive}
                                                placeholder='Seleccione...'
                                                isSearchable={true}
                                                data-cy="select-0000"
                                                inputId='filterOpeActive'
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </>
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={state.loadingModal}
                    color="dark"
                    className='col-12 col-md-3'
                    onClick={() => closeModalFilters(true)}
                    data-cy="close-modal-more-filters-0000"
                >
                    Cerrar
                </Button>
                <Button
                    disabled={state.loadingModal}
                    color="primary"
                    className='col-12 col-md-4'
                    onClick={() => cleanFilters()}
                    data-cy="delete-filters-modal-0000"
                >
                    Eliminar filtros
                </Button>
                <Button
                    disabled={state.loadingModal}
                    color="primary"
                    className='col-12 col-md-4'
                    onClick={() => searchMoreFilters()}
                    data-cy="aplicate-filters-modal-0000"
                >
                    Aplicar filtros
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalFilters;