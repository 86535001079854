import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse
} from 'reactstrap'
import { Accordion } from 'react-bootstrap';
import Contact from "./Contact";

const DetailsTaskChance = ({ data }) => {
    const [isOpen, setIsOpen] = useState(1);
    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

    return (
        <Container fluid="lg" data-cy="container-details-task-chance-0000">
            <Row>
                <Col className="p-0">
                    <Accordion defaultActiveKey="0">
                        <CardBody className="py-2 px-0">
                            <div className="default-according" id="accordion">
                                <Card>
                                    <CardHeader role="button" className="px-0" onClick={() => toggle(1)} >
                                        <Row className="px-2 align-items-center">
                                            <Col sm="12" className="text-left">
                                                <h6 className="mb-0">
                                                    Oportunidad:
                                                </h6>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen === 1}>
                                        <CardBody className="px-4 py-2">
                                            <Row className="mb-4">
                                                {
                                                    typeof data.name !== 'undefined' && (
                                                        <>
                                                            <Col
                                                                style={{ background: 'lightgrey' }}
                                                                className='p-2 border'
                                                                xs="5"
                                                            >
                                                                <h6 className='m-0'>Nombre:</h6>
                                                            </Col>
                                                            <Col xs="7" className='p-2 border'>{data.name}</Col>
                                                        </>
                                                    )
                                                }
                                                {
                                                    typeof data.stage !== 'undefined' && (
                                                        <>
                                                            <Col
                                                                style={{ background: 'lightgrey' }}
                                                                className='p-2 border'
                                                                xs="5"
                                                            >
                                                                <h6 className='m-0'>Etapa:</h6>
                                                            </Col>
                                                            <Col xs="7" className='p-2 border'>{data.stage}</Col>
                                                        </>
                                                    )
                                                }
                                                {
                                                    (typeof data.owner !== 'undefined' && data.owner.name) && (
                                                        <>
                                                            <Col
                                                                style={{ background: 'lightgrey' }}
                                                                className='p-2 border'
                                                                xs="5"
                                                            >
                                                                <h6 className='m-0'>Nombre cliente:</h6>
                                                            </Col>
                                                            <Col xs="7" className='p-2 border'>{data.owner.name}</Col>
                                                        </>
                                                    )
                                                }
                                                {
                                                    (typeof data.owner !== 'undefined' && data.owner.phone) && (
                                                        <>
                                                            <Col
                                                                style={{ background: 'lightgrey' }}
                                                                className='p-2 border d-flex align-items-center'
                                                                xs="5"
                                                            >
                                                                <h6 className='m-0'>Teléfono cliente:</h6>
                                                            </Col>
                                                            <Col xs="7" className='p-2 border contact-owner'>
                                                                {data.owner.phone}
                                                                <Contact phone={data.owner.phone} />
                                                            </Col>
                                                        </>
                                                    )
                                                }
                                                {
                                                    typeof data.created_at !== 'undefined' && (
                                                        <>
                                                            <Col
                                                                style={{ background: 'lightgrey' }}
                                                                className='p-2 border'
                                                                xs="5"
                                                            >
                                                                <h6 className='m-0'>Fecha creación:</h6>
                                                            </Col>
                                                            <Col xs="7" className='p-2 border'>{data.created_at}</Col>
                                                        </>
                                                    )
                                                }
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </div>
                        </CardBody>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default DetailsTaskChance;