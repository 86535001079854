import React, { useEffect } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap'
import ListDetails from '../detailsProperty';
import FormAction from '../actionProperty';
import { registerOpeAction, updatePropertyAction } from '../../../api/propertyDayZone';
import {
    dataOccupiedBy,
    saveRequestPostPutLocalStorage,
    executeRequestPostPutLocalStorage,
    countPendingRecordsRegister
} from '../../../helpers/functions';
import { setCountRecordsNoRegister } from '../../../redux/auth/action';
import { useDispatch } from 'react-redux';


const ModalDetails = ({ state, setState }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (state.details && state.occupiedBy) {
            if (state.comment && state.comment.length >= 10) {
                setState(prev => ({ ...prev, activeUpdate: false }))
            }
            if (state.comment.length < 10 || !state.comment) {
                setState(prev => ({ ...prev, activeUpdate: true }))
            }
        }
    }, [state.occupiedBy, state.comment]);

    useEffect(() => {
        if (state.details && state.provenance && state.action === 'ope') {
            setState(prev => ({ ...prev, activeUpdate: false }))
        }
    }, [state.provenance]);

    const closeModal = () => {
        setState(prev => ({
            ...prev,
            action: '',
            details: null,
            modal: false,
            option: '',
            activeUpdate: true,
            comment: '',
            dataOpportunities: [],
            dataRegisters: [],
            hasActiveOpportunities: null,
            provenance: null,
        }))
    }

    const registerOpe = () => {
        setState(prev => ({ ...prev, loadingForm: true, activeUpdate: true }))
        registerOpeAction({
            property: state.details.find(detail => detail.object === "id_crm").value,
            provenance: state.provenance.value
        })
            .then(() => {
                executeRequestPostPutLocalStorage('ope', state, setState)
                setState(prev => ({ ...prev, loadingForm: false, activeUpdate: false }))
                closeModal()
            })
            .catch(() => {
                saveRequestPostPutLocalStorage('ope', {
                    property: state.details.find(detail => detail.object === "id_crm").value,
                    provenance: state.provenance.value
                })
                dispatch(setCountRecordsNoRegister(countPendingRecordsRegister()))
                setState(prev => ({ ...prev, loadingForm: false, activeUpdate: false }))
                closeModal()
            })
    }

    const showFormUpdate = (action, option) => {
        setState(prev => ({
            ...prev,
            action: action,
            option: option,
            activeUpdate: true,
            occupiedBy: dataOccupiedBy.find(
                arrayOccu => arrayOccu.label === state.details.find(property => property.object === 'occupied_by').value
            )
        }))
    }

    const updateProperty = () => {
        setState(prev => ({ ...prev, loadingForm: true, activeUpdate: true }))

        updatePropertyAction(
            { occupied_by: state.occupiedBy.value, text: state.comment },
            state.details.find(detail => detail.object === "id_crm").value,
            false
        )
            .then(() => {
                let index = state.data.findIndex(property => property.id_crm === state.details.find(detail => detail.object === "id_crm").value)
                state.data[index].occupied_by = state.occupiedBy.label
                executeRequestPostPutLocalStorage('property', state, setState)
                setState(prev => ({ ...prev, loadingForm: false, activeUpdate: false }))
                closeModal()

            })
            .catch(() => {
                saveRequestPostPutLocalStorage('property', {
                    idProperty: state.details.find(detail => detail.object === "id_crm").value,
                    occupied_by: state.occupiedBy.value,
                    text: state.comment
                })
                dispatch(setCountRecordsNoRegister(countPendingRecordsRegister()))
                let index = state.data.findIndex(property => property.id_crm === state.details.find(detail => detail.object === "id_crm").value)
                state.data[index].occupied_by = state.occupiedBy.label
                setState(prev => ({ ...prev, loadingForm: false, activeUpdate: false }))
                closeModal()
            })
    }

    const otherTitles = () => {
        const obj = {
            ope: 'Añadir nueva OPE',
            property: 'Añadir registro de zona'
        }
        return obj[state.action]
    }

    const buttonAction = () => {
        const obj = {
            ope: () => registerOpe(),
            property: () => updateProperty()
        }
        return obj[state.action]()
    }

    return (
        <Modal isOpen={state.modal} scrollable size='lg' data-cy="modal-0000">
            <ModalHeader toggle={closeModal}>
                {state.option === 'details' ? 'Detalles' : otherTitles()}{state.action !== 'ope' ? `: ${state.details[0].value}` : ''}
            </ModalHeader>
            <ModalBody>
                {
                    state.option === 'details' ?
                        <ListDetails
                            data={state.details}
                            loading={state.loadingForm}
                            opportunities={state.dataOpportunities}
                            registers={state.dataRegisters}
                        />
                        :
                        <FormAction
                            setState={setState}
                            state={state}
                        />
                }
            </ModalBody>
            <ModalFooter>
                <div className='buttons-modal-footer'>
                    <Button
                        disabled={state.loadingTable}
                        color="dark"
                        className=''
                        onClick={closeModal}
                        data-cy="close-modal-property-0000"
                    >
                        Cerrar
                    </Button>
                    {/* {
                        (!state.details.find(detail => detail.object === 'owner').value.length && state.option === 'details') ?
                            <Button
                                disabled={state.loadingForm}
                                color="primary"
                                className=''
                                onClick={() => { closeModal(), setState(prev => ({ ...prev, modalOwner: true })) }}
                                data-cy="close-modal-property-0000"
                            >
                                Añadir propietario
                            </Button>
                            :
                            null
                    } */}
                    {
                        (state.hasActiveOpportunities === false && state.option === 'details') ?
                            <Button
                                disabled={state.loadingForm}
                                color="btn btn-warning"
                                className=''
                                onClick={() => showFormUpdate('ope', 'update')}
                                data-cy="register-ope-modal-property-0000"
                            >
                                Crear OPE
                            </Button>
                            :
                            null
                    }
                    <Button
                        disabled={state.option === 'details' ? state.loadingTable : state.activeUpdate}
                        color="primary"
                        className={""}
                        onClick={() => state.option === 'details' ? showFormUpdate('property', 'update') : buttonAction()}
                        data-cy="edit-property-0000"
                    >
                        {state.option === 'details' ? 'Añadir registro de zona' : 'Enviar'}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ModalDetails;