import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Alert
} from 'reactstrap'
import { stateInitital } from '../state/StateProperties'
import { pendingTasksAction } from '../../../api/pendingTasks';
import { openModalTasks, saveRequestSearch, colorRowTableTasks } from '../../../helpers/functions';
import { thereAreNoAssociatedRecords } from '../../../constant/messages';
import ModalTasks from '../Modals/ModalTasks';


const PendingTasks = () => {

    const [state, setState] = useState(stateInitital)

    useEffect(() => {
        pendingTasksAction()
            .then((res) => {
                setState(prev => ({ ...prev, data: res.data.data.tasks, loading: false }))
                saveRequestSearch(
                    'pending-tasks',
                    {
                        params: { pendingTasks: true },
                        data: res.data.data.tasks,
                    }
                )
            })
            .catch((error) => {
                if (error && error.data) {
                    setState(prev => ({ ...prev, loading: false, data: error.data.data, errorMessage: error.message }))
                } else {
                    setState(prev => ({ ...prev, loading: false, data: null, errorMessage: error.message || '' }))
                }
            })
    }, []);

    return (
        <Fragment>
            <Breadcrumb parent="Tareas pendientes" title="Tareas pendientes" />
            <Container fluid={true}>
                {
                    (state.modal) && (
                        <ModalTasks
                            state={state}
                            setState={setState}
                        />
                    )
                }
                <Row>
                    <Col sm="12" className='px-0'>
                        <Card className='card-table'>
                            <CardBody>
                                <Row>
                                    {
                                        state.loading ?
                                            <Col>
                                                <div className="loader-box">
                                                    <div className="loader-9"></div>
                                                </div>
                                            </Col>
                                            :
                                            <>
                                                {
                                                    state.data ?
                                                        <Col sm="12" className='mb-4 px-2'>
                                                            <h6>Tareas pendientes encontradas: {state.data.length}</h6>
                                                        </Col>
                                                        :
                                                        null
                                                }
                                                <Col xs="12" data-cy="col-tasks-pending-0000" className='px-0'>
                                                    {
                                                        state.data ?
                                                            <>
                                                                {
                                                                    state.data.length ?
                                                                        <Table className='table-hover' responsive data-cy="table-results-tasks-pending-0000">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" style={{ width: '15%' }}>Fecha</th>
                                                                                    <th scope="col" style={{ width: '20%' }}>Estado</th>
                                                                                    <th scope="col" style={{ width: '45%' }}>Nombre</th>
                                                                                    <th scope="col" style={{ width: '25%' }}>Tipo</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    state.data.map((task, key) => {
                                                                                        return (
                                                                                            <tr
                                                                                                role='button'
                                                                                                key={key}
                                                                                                className={` ${colorRowTableTasks(task)}`}
                                                                                                onClick={() => openModalTasks(task, setState)}
                                                                                                data-cy={`tr-task-pending-${key}`}
                                                                                            >
                                                                                                <td>
                                                                                                    {task.date}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {task.status}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {task.name ? task.name : ''}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {task.type ? task.type : ''}
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>

                                                                        :
                                                                        <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                                                            <p>{thereAreNoAssociatedRecords}</p>
                                                                        </Alert>
                                                                }
                                                            </>
                                                            :
                                                            state.errorMessage ?
                                                                <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                                                    <p>{state.errorMessage}</p>
                                                                </Alert>
                                                                :
                                                                null
                                                    }
                                                </Col>
                                            </>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default PendingTasks;