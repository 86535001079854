import React, { Fragment, useEffect, useState } from 'react'
import ReactDOM from "react-dom/client";
import './index.scss';
import { BrowserRouter, Navigate, Route, Routes, } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'
import App from './components/app'
import { routes } from './route';
import { getToken } from './helpers/cookies';
import { pushDataGAInit } from './analytics/dataGA';
import { validateVersionAppAction } from './api/login';
// Signin page
import Signin from './auth/signin'
import Loader from './layout/loader';
// Error page
import ErrorIncompatibleVersion from './pages/errors/ErrorIncompatibleVersion';
import ResetPassword from './auth/ResetPassword';
import ConfirmPassword from './auth/ConfirmPassword';
import { ToastContainer } from 'react-toastify'
import { ErrorProvider } from './hooks/ErrorProvider';
import PrivacyPoliciesPage from './pages/privacyPolicies';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://6bd672b80d244ca5b1fb681de3e47cb9@o455531.ingest.sentry.io/6601762",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
  });
}

const Root = (props) => {
  const [version, setVersion] = useState(null)
  const token = getToken();
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      pushDataGAInit()
    }
    validateVersionAppAction()
      .then(() => {
        setVersion(true)
      })
      .catch((e) => {
        setVersion(e)
      })
  }, [])

  return (
    <>
      <Helmet>
        {
          process.env.REACT_APP_NODE_ENV === 'production' && (
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-SWT2K1P406"></script>
          )
        }
      </Helmet>
      <Fragment>
        <ToastContainer data-cy="flash-message-login" limit={2} />
        <ErrorProvider>
          <Provider store={store}>
            {
              version === null ?
                <Loader /> :
                <BrowserRouter>
                  {
                    version === false ?
                      <Routes>
                        <Route path={`/pages/errors/incompatible-version`} element={<ErrorIncompatibleVersion />} />
                        <Route path="*" element={<Navigate replace to="/pages/errors/incompatible-version" />} />
                      </Routes>
                      :
                      token ?
                        <App>
                          <Routes>
                            {routes.map(({ path, Component }) => (
                              <Route key={path} path={`${path}`} element={<Component />} />
                            ))}
                            <Route path="*" element={<Navigate replace to="/" />} />
                          </Routes>
                        </App>
                        :
                        <Routes>
                          <Route path={`/login`} element={<Signin />} />
                          <Route path={`/password-reset`} element={<ResetPassword />} />
                          <Route path={`/password-confirm/:token`} element={<ConfirmPassword />} />
                          <Route path={`/legal/politica-privacidad`} element={<PrivacyPoliciesPage />}></Route>
                          <Route path="*" element={<Navigate replace to="/login" />} />
                        </Routes>

                  }
                </BrowserRouter>
            }
          </Provider>
        </ErrorProvider>
      </Fragment>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.unregister();
