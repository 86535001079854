import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Chart } from "react-google-charts";
import Breadcrumb from '../../layout/breadcrumb'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Alert
} from 'reactstrap'
import { lastRegisterHomeAction, lastRegisterHomeChartAction, lastTasksTodayAction } from '../../api/dashboard';
import { saveRequestSearch, colorRowTableTasks, textsKpsDashboard, openModalTasks, arrayMonths } from '../../helpers/functions';
import { thereAreNoAssociatedRecords, thereAreNotTasks } from '../../constant/messages';
import ModalTasks from '../application/Modals/ModalTasks';
import ModalModeOffLine from '../application/Modals/ModalModeOffLine';

const Default = (props) => {
  const user = useSelector(state => state.authState.profile);
  const dateNow = new Date();

  const options = {
    title: "",
    legend: { position: "none" },
    colors: ['#FF671B'],
  };

  const conditionKps = {
    contacZone: {
      values: {
        one: 180,
        two: 250,
        three: 250
      },
      texts: {
        one: 180,
        two: 250,
        three: 250
      }
    },
    ope: {
      values: {
        one: 10,
        two: 12,
        three: 12
      },
      texts: {
        one: 10,
        two: 12,
        three: 12
      }
    },
    opc: {
      values: {
        one: 6,
        two: 8,
        three: 8
      },
      texts: {
        one: 6,
        two: 8,
        three: 8
      }

    },
    cad: {
      values: {
        one: 6,
        two: 8,
        three: 8
      },
      texts: {
        one: 4,
        two: 6,
        three: 6
      },
    },
    dex: {
      values: {
        one: 6,
        two: 8,
        three: 8
      },
      texts: {
        one: 1,
        two: 2,
        three: 2
      },
    }
  }

  const [state, setState] = useState({
    data: null,
    chartData: null,
    loading: true,
    loadingTasks: true,
    tasks: null,
    loadingChart: true,
    errorMessage: ''
  })

  const [modalModeOffLine, setModalModeOffLine] = useState(false);

  useEffect(() => {
    lastTasksTodayAction()
      .then((res) => {
        setState(prev => ({ ...prev, tasks: res.data.data.tasks, loadingTasks: false }))
        saveRequestSearch(
          'dashboard',
          {
            params: { tasks: true },
            data: res.data.data.tasks,
          }
        )
      })
      .catch((error) => {
        if (error && error.data) {
          setState(prev => ({ ...prev, loadingTasks: false, tasks: error.data.data, errorMessage: error.message }))
        } else {
          setState(prev => ({ ...prev, loadingTasks: false, tasks: null, errorMessage: error.message || '' }))
        }
      })

    lastRegisterHomeChartAction()
      .then((res) => {
        setState(prev => ({ ...prev, chartData: formatData(res.data.data.days), loadingChart: false }))
        saveRequestSearch(
          'dashboard',
          {
            params: { lastRecordsGraph: true },
            data: res.data.data.days,
          }
        )
      })
      .catch((error) => {
        if (error && error.data) {
          setState(prev => ({ ...prev, loadingChart: false, chartData: formatData(error.data.data), errorMessage: error.message }))
        } else {
          setState(prev => ({ ...prev, loadingChart: false, chartData: null, errorMessage: error.message || '' }))
        }
      })

    lastRegisterHomeAction()
      .then((res) => {
        setState(prev => ({
          ...prev,
          data: (res.data.data && res.data.data.this_month) ? res.data.data : null,
          loading: false
        }))
        saveRequestSearch(
          'dashboard',
          {
            params: { lastRecords: true },
            data: res.data.data,
          }
        )
      })
      .catch((error) => {
        if (error && error.data) {
          setState(prev => ({ ...prev, loading: false, data: error.data.data, errorMessage: error.message }))
        } else {
          setState(prev => ({ ...prev, loading: false, data: null, errorMessage: error.message || '' }))
        }
      })
  }, []);

  useEffect(() => {    
    if (localStorage.getItem('modalModeOffLine')) {
      setModalModeOffLine(true)
    }
  }, []);

  const formatData = (data) => {
    let array = [['Year', 'Número de registros', { role: 'annotation' }]]
    data.map((property, key) => {
      array.push([property.day, property.number, property.number])
    })

    return data.length ? array : []
  }

  const validateKps = (node) => {
    return (state.data && state.data.this_month && state.data.this_month[node]) ? state.data.this_month[node] : 0
  }

  return (
    <Fragment>
      <Breadcrumb parent="Inicio" title={`Hola ${user.first_name}`} />
      <Container fluid={true}>
        {
          (modalModeOffLine) && (
            <ModalModeOffLine
              open={modalModeOffLine}
              setOpen={setModalModeOffLine}
            />
          )
        }
        {
          (state.modal) && (
            <ModalTasks
              state={state}
              setState={setState}
            />
          )
        }
        {/* KPI CAPTATION */}
        <Row data-cy="row-dashboard-kpi-captation">
          <Col sm="12" className='px-0 px-sm-2'>
            <Card className='card-table'>
              <CardHeader>
                <h5>KPI de captación</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  {
                    state.loading ?
                      <Col>
                        <div className="loader-box">
                          <div className="loader-9"></div>
                        </div>
                      </Col>
                      :
                      <Col xs="12" data-cy="col-dashboard-kpi-captation" className='px-0 px-sm-2'>
                        <Row>
                          <Col xs='12' sm='6' md='4' xl='3'>
                            <Card>
                              <CardBody>
                                <div className="media align-items-center">
                                  <div className="media-body right-chart-content">
                                    <span className="f-light">Contactos de zona</span>
                                    <div className="d-flex align-items-center gap-1">
                                      <h3>{validateKps("registers")}</h3>
                                      {textsKpsDashboard(validateKps("registers"), conditionKps["contacZone"])}
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs='12' sm='6' md='4' xl='2'>
                            <Card>
                              <CardBody>
                                <div className="media align-items-center">
                                  <div className="media-body right-chart-content">
                                    <span className="f-light">OPE</span>
                                    <div className="d-flex align-items-center gap-1">
                                      <h3>{validateKps("opes")}</h3>
                                      {textsKpsDashboard(validateKps("opes"), conditionKps["ope"])}
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs='12' sm='6' md='4' xl='2'>
                            <Card>
                              <CardBody>
                                <div className="media align-items-center">
                                  <div className="media-body right-chart-content">
                                    <span className="f-light">OPC</span>
                                    <div className="d-flex align-items-center gap-1">
                                      <h3>{validateKps("opcs")}</h3>
                                      {textsKpsDashboard(validateKps("opcs"), conditionKps["opc"])}
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs='12' sm='6' md='6' xl='2'>
                            <Card>
                              <CardBody>
                                <div className="media align-items-center">
                                  <div className="media-body right-chart-content">
                                    <span className="f-light">CAD</span>
                                    <div className="d-flex align-items-center gap-1">
                                      <h3>{validateKps("cads")}</h3>
                                      {textsKpsDashboard(validateKps("cads"), conditionKps["cad"])}
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs='12' md='6' xl='3'>
                            <Card>
                              <CardBody>
                                <div className="media align-items-center">
                                  <div className="media-body right-chart-content">
                                    <span className="f-light">DEX (exclusiva)</span>
                                    <div className="d-flex align-items-center gap-1">
                                      <h3>{validateKps("dexs")}</h3>
                                      {textsKpsDashboard(validateKps("dexs"), conditionKps["dex"])}
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* KPI CAPTATION */}

        {/* CHART */}
        <Row>
          <Col sm="12" className='px-0 px-sm-2'>
            <Card className='card-table'>
              <CardHeader>
                <h5>Número de registros por días de {arrayMonths[dateNow.getMonth()]}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  {
                    state.loadingChart ?
                      <Col>
                        <div className="loader-box">
                          <div className="loader-9"></div>
                        </div>
                      </Col>
                      :
                      <>
                        <Col sm='12' data-cy="col-chart-last-records" className='px-0 px-sm-2'>
                          {
                            state.chartData ?
                              <>
                                {
                                  state.chartData.length ?
                                    <Chart
                                      chartType="ColumnChart"
                                      width="100%"
                                      height="400px"
                                      loader={<div className="loader-box">
                                        <div className="loader-9"></div>
                                      </div>}
                                      data={state.chartData}
                                      options={options}
                                    />
                                    :
                                    <Alert color="secondary dark">
                                      <p>{thereAreNoAssociatedRecords}</p>
                                    </Alert>
                                }
                              </>
                              :
                              state.errorMessage ?
                                <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                  <p>{state.errorMessage}</p>
                                </Alert>
                                :
                                null

                          }
                        </Col>
                      </>
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* CHART */}

        {/* TASKS */}
        <Row>
          <Col sm="12" className='px-0 px-sm-2'>
            <Card className='card-table'>
              <CardHeader>
                <h5>Tus tareas de hoy</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  {
                    state.loadingTasks ?
                      <Col>
                        <div className="loader-box">
                          <div className="loader-9"></div>
                        </div>
                      </Col>
                      :
                      <>
                        <Col xs="12" className='px-0 px-sm-2' data-cy="col-dashboard-tasks-records" style={{ overflowY: 'auto', maxHeight: '500px' }}>
                          {
                            state.tasks ?
                              <>
                                {
                                  state.tasks.length ?
                                    <Table className='table-hover' responsive data-cy="table-dashboard-tasks-0000">
                                      <thead>
                                        <tr>
                                          <th scope="col" style={{ width: '15%' }}>Fecha</th>
                                          <th scope="col" style={{ width: '20%' }}>Estado</th>
                                          <th scope="col" style={{ width: '45%' }}>Nombre</th>
                                          <th scope="col" style={{ width: '20%' }}>Tipo</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          state.tasks.map((task, key) => {
                                            return (
                                              <tr
                                                role='button'
                                                key={key}
                                                className={` ${colorRowTableTasks(task)}`}
                                                onClick={() => openModalTasks(task, setState)}
                                                data-cy={`tr-task-pending-${key}`}
                                              >
                                                <td>
                                                  {task.date}
                                                </td>
                                                <td>
                                                  {task.status}
                                                </td>
                                                <td>
                                                  {
                                                    task.name ?
                                                      task.name
                                                      :
                                                      ''
                                                  }
                                                </td>
                                                <td>
                                                  {task.type ? task.type : ''}
                                                </td>
                                              </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </Table>

                                    :
                                    <Alert color="success dark">
                                      <p>{thereAreNotTasks}</p>
                                    </Alert>
                                }
                              </>
                              :
                              state.errorMessage ?
                                <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                  <p>{state.errorMessage}</p>
                                </Alert>
                                :
                                null
                          }
                        </Col>
                      </>
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* TASKS */}
      </Container>
    </Fragment>
  );
}

export default Default;