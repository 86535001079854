export const stateInitital = {
    action: '',
    dataSelectDays: [],
    dataSelectStreets: [],
    dataSelectNumbers: [],
    dataOpportunities: [],
    dataRegisters: [],
    hasActiveOpportunities: null,
    occupied_by: { label: 'Todos', value: 'todos' },
    contacted: { label: 'Todos', value: 'todos' },
    status: { label: 'Todos', value: 'todos' },
    stage_opc: { label: 'Todas', value: 'todos' },
    has_active_ope: { label: 'Todos', value: 'todos' },
    provenance: null,
    loading: true,
    loadingTable: false,
    loadingModal: false,
    loadingForm: false,
    day: null,
    street_id: null,
    number: null,
    errorMessage: '',
    data: null,
    modal: false,
    modalFilters: false,
    modalOwner: false,
    details: null,
    option: '',
    occupiedBy: null,
    comment: '',
    activeUpdate: false,
    address: '',
    treatment: null,
    invalidtreatment: false,
    names: '',
    invalidnames: false,
    surnames: '',
    invalidsurnames: false,
    mobile: '',
    invalidmobile: false,
    invalidmessagemobile: '',
    email: '',
    invalidemail: false,
    invalidmessageemail: '',
}