import React, { useState } from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    FormFeedback
} from 'reactstrap'
import PrivacyPolicies from '../privacyPolicies'

const ModalPrivacyPolicies = ({ open, setOpen }) => {


    return (
        <Modal isOpen={open} scrollable size='xl' data-cy="modal-privacy-policies-0000">
            <ModalHeader toggle={() => setOpen(false)} >
                Política de privacidad
            </ModalHeader>
            <ModalBody>
                <Row data-cy="row-privacy-policies-0000">
                    <Col>
                        <PrivacyPolicies />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className='buttons-modal-footer' style={{ margin: '0' }}>
                    <Button
                        color="dark"
                        className=''
                        onClick={() => setOpen(false)}
                        data-cy="close-modal-privacy-policies-0000"
                    >
                        Cerrar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ModalPrivacyPolicies