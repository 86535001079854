import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

const Breadcrumbs = (props) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="12" md="6">
              <h3 style={{textTransform:'none'}}>{props.title}</h3>
            </Col>
            <Col xs="12" md="6" className='col d-none d-md-flex justify-content-start justify-content-md-end my-3 my-md-0'>
              <Breadcrumb>
                <BreadcrumbItem><Link to={`/`}><Home /></Link></BreadcrumbItem>
                <BreadcrumbItem>{props.parent}</BreadcrumbItem>
                {/* <BreadcrumbItem active>{props.title}</BreadcrumbItem> */}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Breadcrumbs;