import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import PrivacyPolicies from '../../components/application/privacyPolicies'

const PrivacyPoliciesPage = () => {
    return (
        <Container className='my-4'>
            <Row>
                <Col className="">
                    <PrivacyPolicies />
                </Col>
            </Row>
        </Container>
    )
}

export default PrivacyPoliciesPage