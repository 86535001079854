// dashbaord
import Default from '../components/dashboard/default'
import PropertyDayZone from '../components/application/propertyDayZone'
import PropertyAddress from '../components/application/propertyAddress'
import propertyOpesActive from '../components/application/propertyOpesActive'
import propertyOpcsActive from '../components/application/propertyOpcsActive'
import Profile from '../components/application/profile'
import PendingTasks from '../components/application/pendingTasks'
import PropertiesWithActiveOrder from '../components/application/propertiesWithActiveOrder'

export const routes = [
        { path: "/", Component: Default },
        { path: "/properties/day", Component: PropertyDayZone },
        { path: "/properties/address", Component: PropertyAddress },
        { path: "/properties/opes/active", Component: propertyOpesActive },
        { path: "/properties/opcs/active", Component: propertyOpcsActive },
        { path: "/user/profile", Component: Profile },
        { path: "/properties/charges/active", Component: PropertiesWithActiveOrder },
        { path: "/tasks/pending", Component: PendingTasks },
]