import React from 'react';
import Logo from '../assets/images/logo/login.png';
import { Row, Col, Form, TabContent, TabPane } from 'reactstrap'
import Messages from '../components/messages';
import { Link } from 'react-router-dom';


const CardContainerNoAuth = (props) => {
    return (
        <Row>
            <Col xs="12">
                <div className="login-card" data-cy="login-card-0000">
                    <div>
                        <div className="login-main login-tab">
                            <TabContent activeTab="firebase" className="content-login">
                                <TabPane className="fade show" tabId="firebase">
                                    <Form className="theme-form" autoComplete='off'>
                                        <div>
                                            <a className="logo" href="#">
                                                <img className="img-fluid for-light" src={Logo} alt="logo" />
                                            </a>
                                            <Messages />
                                        </div>
                                        {props.children}
                                        <Row>
                                            <Col xs={12}>
                                                <hr />
                                            </Col>
                                            <Col xs={12} className='text-center'>
                                                <p className='m-0'>Versión: {process.env.REACT_APP_VERSION_COMPILATION}</p>
                                            </Col>
                                            <Col xs={12} className='text-center'>
                                                <Link to='/legal/politica-privacidad' target='_blank'>
                                                    <p className='m-0 text-decoration-underline'>Política de privacidad</p>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default CardContainerNoAuth