import React, { useEffect } from 'react'
import { Col, Progress, Row } from 'reactstrap'
import { loadDaysAllAction, loadStreetsAllAction, loadPropertiesAllAction } from '../../../api/dashboard';
import { LOADED, NOTLOADED } from '../../../constant/messages';

const ProgressBarsModeOffLine = ({
    option,
    loadDays,
    setLoadDays,
    loadStreets,
    setLoadStreets,
    loadProperties,
    setLoadProperties,
    setDays,
    setDataStreets,
    setDataProperties
}) => {

    useEffect(() => {
        if (option === 'si') {
            loadDaysAllAction()
                .then((res) => {
                    setDays(convertDataDays(res.data.data.days))
                    setLoadDays({ load: LOADED, loading: false })
                })
                .catch(() => {
                    setLoadDays({ load: NOTLOADED, loading: false })
                })

            loadStreetsAllAction()
                .then((res) => {
                    setDataStreets(convertDataStreets(res.data.data.streets))
                    setLoadStreets({ load: LOADED, loading: false })
                })
                .catch(() => {
                    setLoadStreets({ load: NOTLOADED, loading: false })
                })

            loadPropertiesAllAction(1, 1000)
                .then((res) => {
                    if (res.data.data.pagination.total_pages > 1) {
                        executeLoadProperties(res.data.data.pagination.total_pages, res.data.data.properties)
                    } else {
                        setDataProperties(convertDataProperties(res.data.data.properties))
                        setLoadProperties({ load: LOADED, loading: false })
                    }

                })
                .catch(() => {
                    setLoadProperties({ load: NOTLOADED, loading: false })
                })
        }
    }, [option]);

    const executeLoadProperties = async (totalPages, properties) => {
        let newArray = properties
        for (let i = 2; i <= totalPages; i++) {
            try {
                const result = await loadPropertiesAllAction(i, 1000);
                if (result.data.meta.success && result.data.data.properties) {
                    newArray.push(...result.data.data.properties)
                    if (i === totalPages) {
                        setDataProperties(convertDataProperties(newArray))
                        setLoadProperties({ load: LOADED, loading: false })
                    }
                } else {
                    setLoadProperties({ load: NOTLOADED, loading: false })
                    break
                }
            } catch (error) {
                setLoadProperties({ load: NOTLOADED, loading: false })
                break
            }
        }
    }

    const convertDataDays = (data) => {
        return data.map((item) => {
            return {
                label: item.name,
                value: item.value,
                streets: item.streets.map((street) => {
                    return {
                        label: street.name,
                        value: street.id_crm
                    }
                })
            }
        })
    }

    const convertDataStreets = (data) => {
        return data.map((item) => {
            return {
                label: item.name,
                value: item.id_crm,
                numbers: item.numbers.map((number) => {
                    return {
                        label: number,
                        value: number
                    }
                })
            }
        })
    }

    const convertDataProperties = (data) => {
        return data.map((item) => {
            return {
                ...item,
                street_id: item.street.id,
                number: item.street_number.toString(),
            }
        })
    }

    return (
        <Col>
            <Row>
                <Col xs='12' className='mb-2'>
                    <h6 onClick={() => setLoadDays({ ...loadDays, load: true })}>
                        Días
                    </h6>
                    <Progress
                        animated
                        className={"my-2 " + (!loadDays.load ? 'progress-days' : '')}
                        value={loadDays.load === LOADED ? 100 : 20}
                    />
                </Col>
                <Col xs='12' className='mb-2'>
                    <h6>
                        Calles
                    </h6>
                    <Progress
                        animated
                        className={"my-2 " + (!loadStreets.load ? 'progress-streets' : '')}
                        value={loadStreets.load === LOADED ? 100 : 30}
                    />
                </Col>
                <Col>
                    <h6>
                        Inmuebles
                    </h6>
                    <Progress
                        animated
                        className={"my-2 " + (!loadProperties.load ? 'progress-properties' : '')}
                        value={loadProperties.load === LOADED ? 100 : 40}
                    />
                </Col>
            </Row>
        </Col>
    )
}

export default ProgressBarsModeOffLine