import React from 'react';
import { useError } from '../../hooks/ErrorProvider';

const Messages = () => {
    const { stateError } = useError()
    
    return (
        stateError.show ?
            <div className={`alert alert-${stateError.type}`} role="alert">
                {stateError.message}
            </div>
            :
            null
    );
}

export default Messages;