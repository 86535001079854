import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Alert,
    Button
} from 'reactstrap'
import ModalDetails from '../Modals/ModalDetails';
import ModalOwner from '../Modals/ModalOwner';
import { stateInitital } from '../state/StateProperties'
import { propertiesWithActiveOrderAction } from '../../../api/propertiesWithActiveOrder';
import { colorRowTableStatus, existModeOffLine, formAddress, openModal, saveRequestSearch } from '../../../helpers/functions';
import { thereAreNoAssociatedRecords } from '../../../constant/messages';

const PropertiesWithActiveOrder = () => {

    const [state, setState] = useState(stateInitital)

    useEffect(() => {
        propertiesWithActiveOrderAction()
            .then((res) => {
                setState(prev => ({ ...prev, data: res.data.data.properties, loading: false }))
                saveRequestSearch(
                    'properties-with-active-order',
                    {
                        params: { propertiesWithActiveOrder: true },
                        data: res.data.data.properties,
                    }
                )
            })
            .catch((error) => {
                if (error && error.data) {
                    setState(prev => ({ ...prev, loading: false, data: existModeOffLine() ? error.data : error.data.data, errorMessage: error.message }))
                } else {
                    setState(prev => ({ ...prev, loading: false, data: null, errorMessage: error.message || '' }))
                }
            })
    }, []);

    return (
        <Fragment>
            <Breadcrumb parent="Inmuebles con encargo activo" title="Inmuebles con encargo activo" />
            <Container fluid={true}>
                {
                    (state.modal && state.details) ?
                        <ModalDetails
                            state={state}
                            setState={setState}
                        />
                        :
                        <></>
                }
                {
                    state.modalOwner ?
                        <ModalOwner
                            state={state}
                            setState={setState}
                        />
                        :
                        <></>
                }
                <Row>
                    <Col sm="12" className='px-0'>
                        <Card className='card-table'>
                            <CardBody>
                                <Row>
                                    {
                                        state.loading ?
                                            <Col>
                                                <div className="loader-box">
                                                    <div className="loader-9"></div>
                                                </div>
                                            </Col>
                                            :
                                            <>
                                                {
                                                    state.data ?
                                                        <Col sm="12" className='mb-4 px-2'>
                                                            <h6>Inmuebles encontrados: {state.data.length}</h6>
                                                        </Col>
                                                        :
                                                        null
                                                }
                                                <Col xs="12" data-cy="col-properties-with-charges-active" className='px-0'>
                                                    {
                                                        state.data ?
                                                            <>
                                                                {
                                                                    state.data.length ?
                                                                        <Table responsive data-cy="table-results-properties-with-charges-active-0000">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" style={{ width: '10%' }}>Ref.</th>
                                                                                    <th scope="col" style={{ width: '15%' }}>Ocupado por</th>
                                                                                    <th scope="col" style={{ width: '40%' }}>Dirección</th>
                                                                                    <th className="d-none d-lg-table-cell" scope="col" style={{ width: '15%' }}>Estado</th>
                                                                                    <th className="d-none d-lg-table-cell" scope="col" style={{ width: '15%' }}>Días sin contactar</th>
                                                                                    <th scope="col" style={{ width: '5%' }}>Acciones</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    state.data.map((property, key) => {
                                                                                        return (
                                                                                            <tr key={key} className={`${colorRowTableStatus(property.status)}`}>
                                                                                                <td
                                                                                                    className='text-primary'
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    title='Ver detalles'

                                                                                                >
                                                                                                    <div
                                                                                                        data-cy="open-modal-view-details-0000"
                                                                                                        onClick={() => openModal(property, 'details', setState)}
                                                                                                    >
                                                                                                        {property.reference}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className='small-font-td'>
                                                                                                    {property.occupied_by}
                                                                                                </td>
                                                                                                <td className='small-font-td'>
                                                                                                    {formAddress(property)}
                                                                                                </td>
                                                                                                <td className="d-none d-lg-table-cell">
                                                                                                    {property.status}
                                                                                                </td >
                                                                                                <td className="d-none d-lg-table-cell">
                                                                                                    {property.days_last_contact}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <Button
                                                                                                        color="primary"
                                                                                                        className='col-12 px-3 w-auto'
                                                                                                        onClick={() => openModal(property, 'update', setState)}
                                                                                                        title="Editar inmueble"
                                                                                                        data-cy="open-modal-edit-0000"
                                                                                                    >
                                                                                                        <i className="fa fa-edit fa-lg mx-1" style={{ cursor: 'pointer' }} />
                                                                                                    </Button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>

                                                                        :
                                                                        <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                                                            <p>{thereAreNoAssociatedRecords}</p>
                                                                        </Alert>
                                                                }
                                                            </>
                                                            :
                                                            state.errorMessage ?
                                                                <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                                                    <p>{state.errorMessage}</p>
                                                                </Alert>
                                                                :
                                                                null
                                                    }
                                                </Col>
                                            </>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default PropertiesWithActiveOrder
    ;