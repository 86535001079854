export const setSignIn = (data) => {
    return ({
        type: "SET_SIGNIN",
        payload: data
    })

}

export const setUserProfile = (data) => {
    return ({
        type: "SET_USER_PROFILE",
        payload: data
    })

}

export const setConnectedUser = (data) => {
    return ({
        type: "SET_CONNECTED_USER",
        payload: data
    })

}

export const setCountRecordsNoRegister = (data) => {
    return ({
        type: "SET_COUNTED_RECORD_NO_REGISTER",
        payload: data
    })

}