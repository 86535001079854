import { Home, MapPin, Map, Check, CheckCircle, List, CheckSquare } from 'react-feather'
export const MENUITEMS = [
    {
        menutitle: "Applications",
        menucontent: "Ready to use Apps",
        Items: [
            {
                path: ``,
                icon: Home,
                type: 'link',
                title: 'Inicio',
                active: false
            },
            {
                path: `/properties/day`,
                icon: Map,
                type: 'link',
                title: 'Inmuebles por día de zona',
                active: false
            },
            {
                path: `/properties/address`,
                icon: MapPin,
                type: 'link',
                title: 'Inmuebles por dirección',
                active: false
            },
            {
                path: `/properties/opes/active`,
                icon: Check,
                type: 'link',
                title: 'Inmuebles con OPE activa',
                active: false
            },
            {
                path: `/properties/opcs/active`,
                icon: CheckCircle,
                type: 'link',
                title: 'Inmuebles con OPC activa',
                active: false
            },
            {
                path: `/properties/charges/active`,
                icon: CheckSquare,
                type: 'link',
                title: 'Inmuebles con encargo activo',
                active: false
            },
            {
                path: `/tasks/pending`,
                icon: List,
                type: 'link',
                title: 'Tareas pendientes',
                active: false
            },
        ]
    }
]