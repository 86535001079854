import React, { } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    FormFeedback
} from 'reactstrap'
import Select from 'react-select';
import { validateEmail } from '../../../helpers/functions';
import { toast } from 'react-toastify';
import { successfullyRegisteredOwner } from '../../../constant/messages';

const ModalOwner = ({ state, setState, filterBasics, action }) => {
    const moreFilters = ['occupied_by', 'contacted', 'status']

    const dataTreatment = [
        {
            label: 'Sr.',
            value: 'Sr.'
        },
        {
            label: 'Sra.',
            value: 'Sra.'
        },
        {
            label: 'Srta.',
            value: 'Srta.'
        }
    ]

    const styleInvalidSelect = {
        border: '1px solid red',
        borderRadius: '0.25rem'
    }


    const handleSelect = (value, name) => {
        setState(prev => ({ ...prev, [name]: value, [`invalid${name}`]: false }))
    }

    const handleChange = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
            [`invalid${e.target.name}`]: false
        }))
    }

    const validate = () => {
        let acum = "";

        if (!state.treatment) {
            setState(prev => ({ ...prev, invalidtreatment: true }))
            acum = 1;
        }
        if (!state.names) {
            setState(prev => ({ ...prev, invalidnames: true }))
            acum = 1;
        }
        if (!state.surnames) {
            setState(prev => ({ ...prev, invalidsurnames: true }))
            acum = 1;
        }
        if (!state.mobile) {
            setState(prev => ({ ...prev, invalidmobile: true, invalidmessagemobile: 'Ingrese el número de su móvil' }))
            acum = 1;

        }
        if (state.mobile && state.mobile.length !== 9) {
            setState(prev => ({ ...prev, invalidmobile: true, invalidmessagemobile: 'El número de móvil debe tener 9 digitos' }))
            acum = 1;
        }
        if (state.email && !validateEmail(state.email)) {
            setState(prev => ({ ...prev, invalidemail: true, invalidmessageemail: 'Email incorrecto' }))
            acum = 1;
        }

        if (acum > 0) {
            return false;
        }
        return true;
    }

    const addOwner = () => {
        const isValid = validate();
        if (isValid) {
            toast.success(successfullyRegisteredOwner, {
                position: "top-right",
                autoClose: 5000,
                theme: "colored",
            });
            setState(prev => ({ ...prev, modalOwner: false }))
        }
    }


    return (
        <Modal isOpen={state.modalOwner} scrollable size='lg' data-cy="modal-filters-0000">
            <ModalHeader toggle={!state.loadingModal ? () => setState(prev => ({ ...prev, modalOwner: false })) : () => { }} >
                Añadir propietario
            </ModalHeader>
            <ModalBody>
                <Row data-cy="row-more-filters-search-day-zone-0000">
                    {
                        state.loadingModal ?
                            <Col>
                                <div className="loader-box">
                                    <div className="loader-9"></div>
                                </div>
                            </Col>
                            :
                            <Col sm="12">
                                <FormGroup row>
                                    <Label sm={3}>
                                        Dirección
                                    </Label>
                                    <Label sm={9}>
                                        {state.address}
                                    </Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="comment"
                                        sm={3}
                                    >
                                        Tratamiento
                                    </Label>
                                    <Col sm={9}>
                                        <div style={state.invalidtreatment ? styleInvalidSelect : {}}>
                                            <Select
                                                value={state.treatment}
                                                onChange={(e) => handleSelect(e, 'treatment')}
                                                options={dataTreatment}
                                                placeholder='Seleccione...'
                                                isSearchable={true}
                                                inputId="treatment"
                                            />
                                        </div>
                                        <FormFeedback style={{ display: 'block' }} data-cy="error-input-email-login-0000">
                                            {
                                                state.invalidtreatment ? "Seleccione el tratamiento" : ''
                                            }
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="names"
                                        sm={3}
                                    >
                                        Nombres
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            value={state.names}
                                            id="names"
                                            name="names"
                                            type="text"
                                            onChange={handleChange}
                                            rows={5}
                                            invalid={state.invalidnames}
                                            data-cy="names-owner"
                                        />
                                        <FormFeedback data-cy="error-input-email-login-0000">
                                            {
                                                state.invalidnames ? "Ingrese nombre(s)" : ''
                                            }
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="surnames"
                                        sm={3}
                                    >
                                        Apellidos
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            value={state.surnames}
                                            id="surnames"
                                            name="surnames"
                                            type="text"
                                            onChange={handleChange}
                                            rows={5}
                                            invalid={state.invalidsurnames}
                                            data-cy="surnames-owner"
                                        />
                                        <FormFeedback data-cy="error-input-email-login-0000">
                                            {
                                                state.invalidsurnames ? "Ingrese apellido(s)" : ''
                                            }
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="mobile"
                                        sm={3}
                                    >
                                        Móvil
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            value={state.mobile}
                                            id="mobile"
                                            name="mobile"
                                            type="number"
                                            onChange={handleChange}
                                            maxLength={9}
                                            rows={5}
                                            invalid={state.invalidmobile}
                                            data-cy="mobile-owner"
                                        />
                                        <FormFeedback data-cy="error-input-email-login-0000">
                                            {state.invalidmessagemobile}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="email"
                                        sm={3}
                                    >
                                        Email
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            value={state.email}
                                            id="email"
                                            name="email"
                                            type="email"
                                            onChange={handleChange}
                                            rows={5}
                                            invalid={state.invalidemail}
                                            data-cy="email-owner"
                                        />
                                        <FormFeedback data-cy="error-input-email-login-0000">
                                            {state.invalidmessageemail}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                            </Col>
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={state.loadingModal}
                    color="dark"
                    className=''
                    // onClick={() => closeModalFilters(true)}
                    data-cy="close-modal-more-filters-0000"
                >
                    Cerrar
                </Button>
                <Button
                    disabled={state.loadingModal}
                    color="primary"
                    className=''
                    onClick={addOwner}
                    data-cy="delete-filters-modal-0000"
                >
                    Enviar
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalOwner;