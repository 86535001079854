import React from "react";
import {
    Container,
    Row,
    Col,    
} from 'reactstrap'

const DetailsTaskBasic = ({ data }) => {

    return (
        <Container fluid="lg" className="mb-3" data-cy="container-details-task-basic-0000">
            <Row>
                {
                    typeof data.status !== 'undefined' && (
                        <>
                            <Col
                                style={{ background: 'lightgrey' }}
                                className='p-2 border'
                                xs="5"
                            >
                                <h6 className='m-0'>Estado:</h6>
                            </Col>
                            <Col
                                xs="7"
                                className='p-2 border'
                            >
                                {data.status}
                            </Col>
                        </>
                    )
                }
                {
                    typeof data.type !== 'undefined' && (
                        <>
                            <Col
                                style={{ background: 'lightgrey' }}
                                className='p-2 border'
                                xs="5"
                            >
                                <h6 className='m-0'>Tipo:</h6>
                            </Col>
                            <Col
                                xs="7"
                                className='p-2 border'
                            >
                                {data.type}
                            </Col>
                        </>
                    )
                }
                {
                    typeof data.overdue !== 'undefined' && (
                        <>
                            <Col
                                style={{ background: 'lightgrey' }}
                                className='p-2 border'
                                xs="5"
                            >
                                <h6 className='m-0'>Vencida:</h6>
                            </Col>
                            <Col
                                xs="7"
                                className='p-2 border'
                            >
                                {data.overdue ? 'Si' : 'No'}
                            </Col>
                        </>
                    )
                }
                {
                    typeof data.date !== 'undefined' && (
                        <>
                            <Col
                                style={{ background: 'lightgrey' }}
                                className='p-2 border'
                                xs="5"
                            >
                                <h6 className='m-0'>Fecha:</h6>
                            </Col>
                            <Col
                                xs="7"
                                className='p-2 border'
                            >
                                {data.date}
                            </Col>
                        </>
                    )
                }
            </Row>
        </Container>
    );
}

export default DetailsTaskBasic;