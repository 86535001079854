import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
} from 'reactstrap'
import ProgressBarsModeOffLine from '../others/ProgressBarsModeOffLine';
import { LOADED, NOTLOADED, SUCCESS, ERROR } from '../../../constant/messages';

const ModalModeOffLine = ({ open, setOpen }) => {
    const [option, setOption] = useState('');
    const [loadDays, setLoadDays] = useState({ load: null, loading: true });
    const [loadStreets, setLoadStreets] = useState({ load: null, loading: true });
    const [loadProperties, setLoadProperties] = useState({ load: null, loading: true });
    const [dataDays, setDays] = useState([]);
    const [dataStreets, setDataStreets] = useState([]);
    const [dataProperties, setDataProperties] = useState([]);
    const [dataOffLine, setDataOffLine] = useState(null);

    useEffect(() => {
        if (loadDays.load === LOADED && loadStreets.load === LOADED && loadProperties.load === LOADED) {
            setOption(SUCCESS)
            setDataOffLine({ days: dataDays, streets: dataStreets, properties: dataProperties })
        }
        if (loadDays.load === NOTLOADED && loadStreets.load === NOTLOADED && loadProperties.load === NOTLOADED) {
            setOption(ERROR)
        }
    }, [loadDays, loadStreets, loadProperties]);

    const closeModal = () => {
        if (localStorage.getItem('dataOffLine')) {
            localStorage.removeItem('dataOffLine')
        }
        if (dataOffLine) {
            localStorage.setItem('dataOffLine', JSON.stringify(dataOffLine))
        }
        localStorage.removeItem('modalModeOffLine')
        setOpen(false)
    }

    return (
        <Modal isOpen={open} scrollable size='md' data-cy="modal-mode-off-line-0000">

            <ModalBody className=''>
                <Row className='h-100 d-flex align-items-center'>
                    {
                        !option && (
                            <Col>
                                <h6 className='m-0 lh-base' style={{}}>
                                    ¿Deseas crear una copia de los inmuebles en tu dispositivo, permitiéndote acceder a ellos incluso
                                    en situaciones donde la conexión se pierda?
                                </h6>
                            </Col>
                        )
                    }
                    {
                        option === 'si' && (
                            <ProgressBarsModeOffLine
                                option={option}
                                loadDays={loadDays}
                                setLoadDays={setLoadDays}
                                loadStreets={loadStreets}
                                setLoadStreets={setLoadStreets}
                                loadProperties={loadProperties}
                                setLoadProperties={setLoadProperties}
                                setDays={setDays}
                                setDataStreets={setDataStreets}
                                setDataProperties={setDataProperties}
                            />
                        )
                    }
                    {
                        option === SUCCESS && (
                            <Col>
                                <h6 className='m-0 lh-base'>
                                    La copia de los inmuebles en tu dispositivo se ha llevado a cabo de manera exitosa. Ahora puedes
                                    acceder a ellas sin problemas, incluso cuando no dispongas de conexión.
                                </h6>
                            </Col>
                        )
                    }
                    {
                        option === ERROR && (
                            <Col>
                                <h6 className='m-0 lh-base'>
                                    Ha ocurrido un error durante el proceso de copia de los inmueble en tu dispositivo. Por favor, verifica tu
                                    conexión a internet y vuelve a intentarlo. Si el problema persiste, no dudes en ponerte en contacto con nosotros
                                    por email a <a href='mailto:zona@grocasa.com'>zona@grocasa.com</a>.<br />
                                    Lamentamos cualquier inconveniente.
                                </h6>
                            </Col>
                        )
                    }
                </Row>
            </ModalBody>
            <ModalFooter className='px-0'>
                {
                    !option && (
                        <div className='buttons-modal-off-line'>
                            <Button
                                color="dark"
                                className='h-100'
                                onClick={() => closeModal()}
                                data-cy="close-modal-property-0000"
                            >
                                No
                            </Button>
                            <Button
                                color="primary"
                                className='h-100'
                                onClick={() => setOption('si')}
                                data-cy="close-modal-property-0000"
                            >
                                Sí
                            </Button>
                        </div>
                    )
                }
                {
                    (option === 'si' || option === SUCCESS || option === ERROR) && (
                        <div className='buttons-modal-off-line-close'>
                            <Button
                                disabled={(loadDays.load && loadStreets.load && loadProperties.load) ? false : true}
                                color={(loadDays.load === LOADED && loadStreets.load === LOADED && loadProperties.load === LOADED) ?
                                    "primary" :
                                    "dark"
                                }
                                className='w-100'
                                onClick={() => closeModal()}
                                data-cy="close-modal-property-0000"
                            >
                                Cerrar
                            </Button>
                        </div>
                    )
                }
            </ModalFooter>
        </Modal>
    )
}

export default ModalModeOffLine