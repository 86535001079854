import axios from "axios";
import { toast } from 'react-toastify';
import { apiURL } from "../helpers/functions";
import { errorAction } from "../helpers/functions";
import { dataHeaders } from "../helpers/functions";
import {
    errorConsultingTheDays,
    errorConsultingTheProperties,
    successfullyModifiedProperty,
    errorConsultingTheStreetNumbers,
    errorConsultingTheOpportunities,
    errorQueryingLogData,
    opeCreatedSuccessfully,
    errorRegisteringTheOpe,
    errorUpdatingTheProperty
} from "../constant/messages";

export const selectDaysAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/days`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorConsultingTheDays, { days: true }, 'day-zone'))
            });
    })
}

export const propertiesDaysAction = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${apiURL()}/api/app/register/properties/`,
            data,
            dataHeaders()
        )
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorConsultingTheProperties, data, 'day-zone'))
            });
    })
}

export const updatePropertyAction = (data, id, hideMessage) => {
    return new Promise((resolve, reject) => {
        axios.put(
            `${apiURL()}/api/app/register/property/${id}`,
            data,
            dataHeaders()
        )
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                    if (!hideMessage) {
                        toast.success(successfullyModifiedProperty, {
                            position: "top-right",
                            autoClose: 5000,
                            theme: "colored",
                        });
                    }
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorUpdatingTheProperty, null, null, true))
            });
    })
}

export const selectStreetsNumberAction = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiURL()}/api/app/register/streets`, data, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorConsultingTheStreetNumbers))
            });
    })
}

export const loadOpportunitiesProperty = (idCrm) => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/property/${idCrm}/opes`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorConsultingTheOpportunities, null, null, null, false))
            });
    })
}

export const loadDataRegisterProperty = (idCrm) => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/property/${idCrm}/registers`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorQueryingLogData, null, null, null, false))
            });
    })
}

export const registerOpeAction = (data, hideMessage) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${apiURL()}/api/app/register/ope`,
            data,
            dataHeaders()
        )
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                    if (!hideMessage) {
                        toast.success(opeCreatedSuccessfully, {
                            position: "top-right",
                            autoClose: 5000,
                            theme: "colored",
                        });
                    }
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorRegisteringTheOpe, null, null, true))
            });
    })
}
