import React from "react";
import {
    Container,
    Col
} from 'reactstrap'
import UpdateProperty from "./UpdateProperty";
import RegisterOpe from "./RegisterOpe";

const FormAction = ({
    setState,
    state,
}) => {

    const renderForm = (action) => {
        const obj = {
            ope: <RegisterOpe setState={setState} state={state} />,
            property: <UpdateProperty setState={setState} state={state} />
        }
        return obj[action]
    }

    return (
        <Container fluid="lg">
            {
                state.loadingForm ?
                    <Col>
                        <div className="loader-box">
                            <div className="loader-9"></div>
                        </div>
                    </Col>
                    :
                    renderForm(state.action)
            }
        </Container>
    )
}

export default FormAction