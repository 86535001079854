const initial_state = {
    token: "",
    expired: 0,
    loggedNow: false,
    profile: null,
    connected: navigator.onLine,
    countRecords: 0,
}

const AuthReducer = (state = initial_state, action) => {
    switch (action.type) {

        case 'SET_SIGNIN':
            return {
                ...state,
                token: action.payload.access_token,
                expired: action.payload.expires_in,
                loggedNow: true
            }
        case 'SET_USER_PROFILE':
            return {
                ...state,
                profile: action.payload,
            }
        case 'SET_CONNECTED_USER':
            return {
                ...state,
                connected: action.payload,
            }
        case 'SET_COUNTED_RECORD_NO_REGISTER':
            return {
                ...state,
                countRecords: action.payload,
            }

        default: return { ...state };
    }
}

export default AuthReducer;
