import React, { Fragment, useEffect, useState } from 'react';
import Loader from "../layout/loader";
import Taptop from "../layout/tap-top";
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Footer from '../layout/footer'
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../helpers/cookies';
import { meAction, heartBeatAction } from '../api/login';
import { setUserProfile, setConnectedUser, setCountRecordsNoRegister } from '../redux/auth/action';
import { executeRequestPostPutLocalStorageSetInterval, deletePostPutRegistersLocalStorage, countPendingRecordsRegister } from '../helpers/functions';
import { offline, haveConnectionAgain } from '../constant/messages';

const App = ({ children }) => {
  const dispatch = useDispatch()
  const countRecords = useSelector(state => state.authState.countRecords);
  const token = getToken();
  const [validateUser, setValidateUser] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    //deletePostPutRegistersLocalStorage()
  }, []);

  useEffect(() => {
    if (token) {
      meAction()
        .then((res) => {
          dispatch(setUserProfile(res.data.data))
          setValidateUser(true);
        })
        .catch(() => {
          setValidateUser(false);
        })
    }
  }, [token]);

  useEffect(() => {
    if (countRecords) {
      toast.update('toastOffline', {
        render: `${offline} . Tiene ${countRecords === 1 ?
          `${countRecords} cambio pendiente` :
          `${countRecords} cambios pendientes`} de enviar`,
      });
    }
  }, [countRecords]);

  useEffect(() => {
    const handleStatusChange = () => {
      if (!navigator.onLine) {
        toast.warning(offline, {
          position: "bottom-right",
          autoClose: false,
          theme: "colored",
          closeButton: false,
          toastId: 'toastOffline'
        });
        dispatch(setCountRecordsNoRegister(countPendingRecordsRegister()))
      } else {
        heartBeatAction()
          .then(() => {
            toast.dismiss("toastOffline");
            toast.success(haveConnectionAgain, {
              position: "bottom-right",
              autoClose: 5000,
              theme: "colored",
              toastId: 'toastOnline'
            });
            executeRequestPostPutLocalStorageSetInterval()
          })
          .catch(() => { })

      }
      setIsOnline(navigator.onLine);
      dispatch(setConnectedUser(navigator.onLine))
    };

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);
  
  return (
    <Fragment>
      <ToastContainer limit={2} />
      {
        validateUser ?
          <>
            <Taptop />
            <div className="page-wrapper compact-wrapper" id="pageWrapper">
              <Header />
              <div className="page-body-wrapper">
                <Sidebar />
                <div className="page-body">
                  {children}
                </div>
                <Footer />
              </div>

            </div>
            <ThemeCustomize />
          </>
          :
          <Loader />
      }
    </Fragment>
  );
}
export default App;