import React from "react";
import {
    Container,
    Col,
    FormGroup,
    Label,
    Input
} from 'reactstrap'
import Select from 'react-select';

const RegisterOpe = ({
    setState,
    state,
}) => {

    const dataProvenance = [
        {
            "label": "Prospección de zona",
            "value": "Zona"
        },
        {
            "label": "Acción solidaria",
            "value": "Acción solidaria"
        },
        {
            "label": "Acción/taller oficina",
            "value": "Acción/taller oficina"
        },
        {
            "label": "Amigo o familiar",
            "value": "Amigo o familiar"
        },
        {
            "label": "Antiguo cliente",
            "value": "Antiguo cliente"
        },
        {
            "label": "Cercanía a la oficina",
            "value": "Cercanía a la oficina"
        },
        {
            "label": "Certificado de defunción",
            "value": "Certificado de defunción"
        },
        {
            "label": "Certificado energético",
            "value": "Certificado energético"
        },
        {
            "label": "Código QR",
            "value": "Código QR"
        },
        {
            "label": "Escaparate",
            "value": "Escaparate"
        },
        {
            "label": "Ferias",
            "value": "Ferias"
        },
        {
            "label": "Fotocasa",
            "value": "Fotocasa"
        },
        {
            "label": "Grocasa Alquiler",
            "value": "Grocasa Alquiler"
        },
        {
            "label": "Groinversia",
            "value": "Groinversia"
        },
        {
            "label": "Habitaclia",
            "value": "Habitaclia"
        },
        {
            "label": "Idealista",
            "value": "Idealista"
        },
        {
            "label": "Llamada",
            "value": "Llamada"
        },
        {
            "label": "OCA Grocasa",
            "value": "OCA Grocasa"
        },
        {
            "label": "OPC Recuperada",
            "value": "OPC Recuperada"
        },
        {
            "label": "Otros portales",
            "value": "Otros portales"
        },
        {
            "label": "Pegatina",
            "value": "Pegatina"
        },
        {
            "label": "Publicidad exterior",
            "value": "Publicidad exterior"
        },
        {
            "label": "Referido Cliente",
            "value": "Referido Cliente"
        },
        {
            "label": "Referido Oficinas",
            "value": "Referido Oficinas"
        },
        {
            "label": "Revista",
            "value": "Revista"
        },
        {
            "label": "Web Grocasa",
            "value": "Web Grocasa"
        }
    ]

    const handleChangeProvenance = (value) => {
        setState(prev => ({ ...prev, provenance: value ? value : null }))
    }

    return (
        <>
            <FormGroup row>
                <Label sm={3}>
                    Dirección
                </Label>
                <Label sm={9}>
                    {state.address}
                </Label>
            </FormGroup>
            <FormGroup row className="mb-5">
                <Label
                    for="provenance"
                    sm={3}
                >
                    Procedencia
                </Label>
                <Col sm={9}>
                    <Select
                        value={state.provenance}
                        onChange={handleChangeProvenance}
                        options={dataProvenance}
                        placeholder='Seleccione...'
                        isSearchable={true}
                        inputId="provenanceByEdit"
                        menuPosition="fixed"
                    />
                </Col>
            </FormGroup>
        </>
    )
}

export default RegisterOpe