import { toast } from 'react-toastify';
import { getToken, removeToken } from './cookies';
import {
    loadOpportunitiesProperty,
    loadDataRegisterProperty,
    updatePropertyAction,
    registerOpeAction
} from '../api/propertyDayZone';
import {
    tokenExpired,
    failedToGetResults,
    pleaseTryLater,
    recoveredDataOffline,
    pendingChanges,
    haveConnectionAgain
} from '../constant/messages';
const CryptoJS = require("crypto-js");

export const apiURL = () => {
    return process.env.REACT_APP_API_URL;
};

export const validateEmail = (email) => {
    let emailValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailValid.test(email)
}

export const errorAction = (error, message, paramsLocalStorage, module, registerAct, showAlert) => {
    let dataError = {
        message: '',
        data: null,
        showAlert: showAlert ?? true
    };

    if (error.response) {
        if (error.response.status === 401) {
            if (getToken()) {
                setTimeout(() => {
                    removeToken();
                    sessionStorage.setItem('logout', true)
                    localStorage.removeItem('modalModeOffLine')
                    window.location.href = `/login`;
                }, 3000)
            }
            toast.error(tokenExpired, {
                position: "top-right",
                autoClose: 5000,
                theme: "colored",
            });
            dataError.message = tokenExpired;
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                theme: "colored",
            });
            dataError.message = message
        }

    } else if (error.request) {
        let messageOffline = `En este momento, no hay conexión a internet. ${message}!`;
        let typeToast = 'error'
        dataError.message = failedToGetResults;

        if (paramsLocalStorage && !navigator.onLine) {
            //console.log('primera opcion ', findLocalStorage)
            let findLocalStorage = loadSaveRequestSearch(module, paramsLocalStorage)
            if (findLocalStorage) {
                typeToast = 'warning'
                dataError.data = findLocalStorage;
                dataError.message = '';
                messageOffline = `${message}, ${recoveredDataOffline}`
                dataError.showAlert = (findLocalStorage.data.length || Object.keys(findLocalStorage.data).length) ? true : false
            }
        }

        if (paramsLocalStorage &&
            !navigator.onLine &&
            existModeOffLine() &&
            !['pending-tasks', 'dashboard'].includes(module)
        ) {
            //console.log('opcion modo offline ', loadDataModeOffLIne(paramsLocalStorage))
            if (loadDataModeOffLIne(paramsLocalStorage) && loadDataModeOffLIne(paramsLocalStorage).length) {
                dataError.data = loadDataModeOffLIne(paramsLocalStorage);
                dataError.message = '';
            } else {
                dataError.message = failedToGetResults;
            }
            dataError.showAlert = false;
        }

        if (registerAct && !navigator.onLine) {
            messageOffline = `En este momento, no hay conexión a internet. Los datos se guardarán automáticamente una vez que la conexión se restablezca.`;
            typeToast = 'warning'
        }

        if (dataError.showAlert) {
            toast[typeToast](navigator.onLine ?
                `¡${message}, ${pleaseTryLater}` :
                messageOffline, {
                position: "top-right",
                autoClose: 5000,
                theme: "colored",
            });
        }

    } else {
        if (dataError.showAlert) {
            toast.error(`¡${message}, ${pleaseTryLater}`, {
                position: "top-right",
                autoClose: 5000,
                theme: "colored",
            });
        }
        dataError.message = failedToGetResults;
    }
    return dataError;
}

export const dataHeaders = () => {
    return {
        headers: { Authorization: `Bearer ${getToken()}` },
    }
}

export const dataDetailsPropertyConvert = (data) => {
    const newDetails = [
        {
            label: 'Referencia',
            object: 'reference',
            show: true,
            value: data.reference
        },
        {
            label: 'Día de zona',
            show: true,
            value: data.day
        },
        {
            label: 'Dirección',
            show: true,
            value: formAddress(data)

        },
        {
            label: 'Nombre Propietario',
            show: true,
            value: data.owner.name
        },
        {
            label: 'Teléfono Propietario',
            object: 'phone',
            show: true,
            value: data.owner.phone
        },
        {
            label: 'Días sin contactar',
            show: true,
            value: data.days_last_contact,
            object: 'days_without_contact'
        },
        {
            label: 'Estado',
            show: true,
            value: data.status
        },
        {
            label: 'Notas internas',
            show: true,
            value: data.internal_notes
        },
        {
            label: 'Ocupador por',
            object: 'occupied_by',
            show: false,
            value: data.occupied_by
        },
        {
            label: 'Id',
            object: 'id_crm',
            show: false,
            value: data.id_crm
        },
        {
            label: 'Propietario',
            object: 'owner',
            show: false,
            value: data.owner
        },
    ]

    return newDetails;
}

export const dataOccupiedBy = [
    {
        label: 'Pendiente',
        value: 'pendent'
    },
    {
        label: 'Propietario',
        value: 'owner'
    },
    {
        label: 'Inquilino',
        value: 'tenant'
    },
    {
        label: 'Ocupado',
        value: 'occupied'
    },
    {
        label: 'Vacío',
        value: 'empty'
    },
    {
        label: 'Vacacional',
        value: 'holiday'
    }
]

export const formAddress = (data) => {
    return `${data.street.name} Nº ${data.street_number},${data.staircase ? ` ESC. ${data.staircase}` : ' '} ${data.floor ? data.floor : ''} ${data.door ? data.door : ''}`
}

export const encryptDataUSer = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'infoUser').toString();
}

export const decryptDataUSer = (data) => {
    let bytes = CryptoJS.AES.decrypt(data, 'infoUser');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export const colorRowTableStatus = (data) => {
    switch (data) {
        case 'Disponible venta':
            return 'table-success'

        case 'Disponible alquiler':
            return 'table-warning'

        case 'Captación':
            return 'table-info'

        case 'Reservado':
            return 'table-danger'

        case 'Retirado':
            return ''

        default:
            return ''
    }
}

export const colorRowTableTasks = (data) => {
    if (data.done) {
        return 'table-success'
    }
    if (data.overdue) {
        return 'table-danger'
    }
    return ''
}

// export const moreFilterOptions = ['occupied_by', 'contacted', 'status']

export const paylodadEndPointSearchProperty = (state, filterBasics, dataHandle) => {
    const moreFilters = ['occupied_by', 'contacted', 'status', 'has_active_ope', 'stage_opc']
    let quantityFiltersOthers = 0;

    let newFilters = new Object();
    moreFilters.map(filter => {
        if (state[filter] && state[filter].value !== 'todos') {
            newFilters[filter] = state[filter].value
            quantityFiltersOthers++;
        }
    })

    if (Object.keys(newFilters).length || dataHandle) {
        filterBasics.map(filter => {
            if (state[filter]) {
                newFilters[filter] = state[filter].value
            }
        })
    }

    if (dataHandle) {
        newFilters[dataHandle.name] = dataHandle.value
    }

    return {
        payload: newFilters,
        filtersOthers: quantityFiltersOthers
    }
}

export const paylodadCleanEndPointSearchProperty = (state, filterBasics) => {
    let newFilters = new Object();
    filterBasics.map(filter => {
        if (state[filter]) {
            newFilters[filter] = state[filter].value
        }
    })
    return newFilters;
}

export const convertDateLastTwentyRegister = (date) => {
    let firstConvert = date.split(" ")
    let dateFormat = `${firstConvert[0].split("-")[2]}-${firstConvert[0].split("-")[1]}-${firstConvert[0].split("-")[0]}`
    let timeFormat = `${firstConvert[1].split(":")[0]}:${firstConvert[1].split(":")[1]}`
    return `${dateFormat} ${timeFormat}`
}

export const openModal = (data, option, setState) => {
    setState(prev => ({ ...prev, details: dataDetailsPropertyConvert(data), address: formAddress(data) }))

    if (option === 'details') {
        setState(prev => ({ ...prev, loadingForm: true }))
        loadOpportunitiesProperty(data.id_crm)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    dataOpportunities: res.data.data.opportunities,
                    hasActiveOpportunities: res.data.data.has_active,
                    loadingForm: false
                }))
            })
            .catch(() => {
                setState(prev => ({ ...prev, loadingForm: false, hasActiveOpportunities: null, dataOpportunities: null }))
            })

        loadDataRegisterProperty(data.id_crm)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    dataRegisters: res.data.data.registers,
                    loadingForm: false
                }))
            })
            .catch(() => {
                setState(prev => ({ ...prev, loadingForm: false, dataRegisters: null }))
            })
    }

    if (option === 'update') {
        setState(prev => ({
            ...prev,
            action: 'property',
            occupiedBy: dataOccupiedBy.find(arrayOccu => arrayOccu.label === data.occupied_by),
            activeUpdate: true
        }))
    }
    setState(prev => ({ ...prev, option: option, modal: true }))
}

export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return {
            url: process.env.REACT_APP_URL_ACT_ANDROID,
            platform: "windowsPhone"
        };
    }

    if (/android/i.test(userAgent)) {
        return {
            url: process.env.REACT_APP_URL_ACT_ANDROID,
            platform: "android"
        };
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return {
            url: process.env.REACT_APP_URL_ACT_IOS,
            platform: "ios"
        };
    }

    return {
        url: process.env.REACT_APP_URL_ACT_ANDROID,
        platform: "desconocido"
    };;
}

export const makeRandomId = (length) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function saveRequestPostPutLocalStorage(type, data) {
    let itemLocalStorage = JSON.parse(localStorage.getItem('post-put'))
    let arrayRequest = [];
    if (itemLocalStorage) {
        if (Object.keys(itemLocalStorage).some(data => data === type)) {
            data.id = makeRandomId(18)
            itemLocalStorage[type].push(data)
            localStorage.setItem('post-put', JSON.stringify(itemLocalStorage))
        } else {
            data.id = makeRandomId(18)
            arrayRequest.push(data)
            itemLocalStorage[type] = arrayRequest
            localStorage.setItem('post-put', JSON.stringify(itemLocalStorage))
        }
    } else {
        data.id = makeRandomId(18)
        arrayRequest.push(data)
        let object = {}
        object[type] = arrayRequest
        localStorage.setItem('post-put', JSON.stringify(object))
    }
}

export function executeRequestPostPutLocalStorage(type, state, setState) {
    let itemLocalStorage = JSON.parse(localStorage.getItem('post-put'))
    if (itemLocalStorage && Object.keys(itemLocalStorage).some(data => data === type)) {
        if (type === 'property') {
            if (itemLocalStorage[type].some(
                props => props.idProperty === state.details.find(detail => detail.object === "id_crm").value)
            ) {
                let filter = itemLocalStorage[type].filter(prop => prop.idProperty !== state.details.find(detail => detail.object === "id_crm").value)
                itemLocalStorage[type] = filter
                localStorage.setItem('post-put', JSON.stringify(itemLocalStorage))
            }

            let arrayLocalStorageProperties = itemLocalStorage[type]
            arrayLocalStorageProperties.map((item) => {
                updatePropertyAction(
                    {
                        occupied_by: item.occupied_by,
                        text: item.text
                    },
                    item.idProperty,
                    true
                )
                    .then(() => {
                        itemLocalStorage[type].splice(itemLocalStorage[type].findIndex(ind => ind.id === item.id), 1);
                        localStorage.setItem('post-put', JSON.stringify(itemLocalStorage))
                        validateDataPostPutLocalStorage()
                        /////////////////////////////////////////////////////////////////////////////////////////////////
                        let index = state.data.findIndex(property => property.id_crm === item.idProperty)

                        if (index >= 0) {
                            state.data[index].occupied_by = dataOccupiedBy.find(occupied => occupied.value === item.occupied_by).label
                            setState(prev => ({ ...prev, data: state.data }))
                        }
                    })
                    .catch(() => {
                        //saveRequestPostPutLocalStorage('property', item)
                    })
            })
        } else {
            if (itemLocalStorage[type].some(
                props => props.property === state.details.find(detail => detail.object === "id_crm").value)
            ) {
                let filter = itemLocalStorage[type].filter(prop => prop.property !== state.details.find(detail => detail.object === "id_crm").value)
                itemLocalStorage[type] = filter
                localStorage.setItem('post-put', JSON.stringify(itemLocalStorage))
            }
            let arrayLocalStorageOpes = itemLocalStorage[type]
            arrayLocalStorageOpes.map((item) => {
                registerOpeAction(
                    {
                        property: item.property,
                        provenance: item.provenance
                    },
                    true
                )
                    .then(() => {
                        itemLocalStorage[type].splice(itemLocalStorage[type].findIndex(ind => ind.id === item.id), 1);
                        localStorage.setItem('post-put', JSON.stringify(itemLocalStorage))
                        validateDataPostPutLocalStorage()
                    })
                    .catch(() => {
                        //saveRequestPostPutLocalStorage('ope', item)
                    })
            })
        }
    }
}

export function executeRequestPostPutLocalStorageSetInterval() {
    let itemLocalStorage = JSON.parse(localStorage.getItem('post-put'))
    if (itemLocalStorage['property']) {
        let arrayLocalStorageProperties = itemLocalStorage['property']
        arrayLocalStorageProperties.map((item) => {
            updatePropertyAction(
                {
                    occupied_by: item.occupied_by,
                    text: item.text
                },
                item.idProperty,
                true
            )
                .then(() => {
                    itemLocalStorage['property'].splice(itemLocalStorage['property'].findIndex(ind => ind.id === item.id), 1);
                    localStorage.setItem('post-put', JSON.stringify(itemLocalStorage))
                    validateDataPostPutLocalStorage()
                })
                .catch(() => {
                    //saveRequestPostPutLocalStorage('property', item)                    
                })
        })
    }
    if (itemLocalStorage['ope']) {
        let arrayLocalStorageOpe = itemLocalStorage['ope']
        arrayLocalStorageOpe.map((item) => {
            registerOpeAction(
                {
                    property: item.property,
                    provenance: item.provenance
                },
                true
            )
                .then(() => {
                    itemLocalStorage['ope'].splice(itemLocalStorage['ope'].findIndex(ind => ind.id === item.id), 1);
                    localStorage.setItem('post-put', JSON.stringify(itemLocalStorage))
                    validateDataPostPutLocalStorage()
                })
                .catch(() => {
                    //saveRequestPostPutLocalStorage('ope', item)
                })
        })
    }
}

export function saveRequestSearch(module, data) {
    let itemLocalStorage = JSON.parse(localStorage.getItem('search'))
    let arrayRequest = [];
    if (itemLocalStorage) {
        if (itemLocalStorage[module]) {
            let indexParms = itemLocalStorage[module].findIndex(searchSave => JSON.stringify(searchSave.params) === JSON.stringify(data.params))
            if (indexParms !== -1) {
                itemLocalStorage[module][indexParms] = data
                localStorage.setItem('search', JSON.stringify(itemLocalStorage))
            } else {
                itemLocalStorage[module].push(data)
                localStorage.setItem('search', JSON.stringify(itemLocalStorage))
            }
        } else {
            arrayRequest.push(data)
            itemLocalStorage[module] = arrayRequest
            localStorage.setItem('search', JSON.stringify(itemLocalStorage))
        }
    } else {
        let objectModules = {};
        arrayRequest.push(data)
        objectModules[module] = arrayRequest
        localStorage.setItem('search', JSON.stringify(objectModules))
    }
}

export function loadSaveRequestSearch(module, params) {
    let itemLocalStorage = JSON.parse(localStorage.getItem('search'))
    if (itemLocalStorage) {
        if (itemLocalStorage[module]) {
            let find = itemLocalStorage[module].find(data => JSON.stringify(data.params) === JSON.stringify(params))
            if (find) {
                return find
            }
            return null
        }
        return null
    }
    return null
}

export function validateDataPostPutLocalStorage() {
    let itemLocalStorage = JSON.parse(localStorage.getItem('post-put'))
    let localOpe = false
    let localProperty = false
    if (itemLocalStorage) {
        if (itemLocalStorage['ope']) {
            if (!itemLocalStorage['ope'].length) {
                localOpe = true
            }
        } else {
            localOpe = true
        }

        if (itemLocalStorage['property']) {
            if (!itemLocalStorage['property'].length) {
                localProperty = true
            }
        } else {
            localProperty = true
        }

        if (localOpe && localProperty) {
            // toast.success(pendingChanges, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     theme: "colored",
            // });
            toast.update('toastOnline', {
                render: `${haveConnectionAgain} y se han enviado los cambios pendientes`,
            });
        }
    }
}

export function deletePostPutRegistersLocalStorage() {
    if (localStorage.getItem('post-put')) {
        localStorage.removeItem('post-put')
    }
}

export function textsKpsDashboard(value, data) {
    if (parseFloat(value) <= parseFloat(data.values.one)) {
        return <span className="font-danger f-12 f-w-500"><span>{`<=${data.texts.one}`}</span></span>
    }
    if (parseFloat(value) <= parseFloat(data.values.two)) {
        return <span className="font-warning f-12 f-w-500"><span>{`<=${data.texts.two}`}</span></span>
    }
    if (parseFloat(value) > parseFloat(data.values.three)) {
        return <span className="font-success f-12 f-w-500"><span>{`>${data.texts.three}`}</span></span>
    }
}

export const openModalTasks = (data, setState) => {
    setState(prev => ({ ...prev, details: data, modal: true }))
}

export const arrayMonths = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
]

export const meetsCriteriaModoOffLine = (obj, params) => {
    return Object.keys(params).every(key => {
        return obj.hasOwnProperty(key) && obj[key] === params[key];
    });
}

export const existModeOffLine = () => {
    return localStorage.getItem('dataOffLine') ? true : false;
}

export const loadDataModeOffLIne = (params) => {
    let data = JSON.parse(localStorage.getItem('dataOffLine'))
    let key = Object.keys(params).toString()
    let othersProperties = {
        ope: 'has_ope',
        opc: 'has_opc',
        propertiesWithActiveOrder: 'has_charge',
    }

    if (['days', 'streets'].includes(key)) {
        return data[key]
    } else if (['ope', 'opc', 'propertiesWithActiveOrder'].includes(key)) {
        let keyParam = othersProperties[key]
        return data.properties.filter(property => property[keyParam]);
    } else {
        return data.properties.filter(property => meetsCriteriaModoOffLine(property, params));
    }
}

export const countPendingRecordsRegister = () => {
    let itemLocalStorage = JSON.parse(localStorage.getItem('post-put'))
    let count = 0
    if (itemLocalStorage) {
        if (itemLocalStorage['ope']?.length) {
            count += itemLocalStorage['ope']?.length
        }
        if (itemLocalStorage['property']?.length) {
            count += itemLocalStorage['property']?.length
        }
    }
    return count
}