import React, { useEffect } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap'
import DetailsTaskBasic from '../others/DetailsTask';
import DetailsTaskCustomer from '../others/DetailsTaskCustomer';
import DetailsTaskProperty from '../others/DetailsTaskProperty';
import DetailsTaskChance from '../others/DetailsTaskChance';

const ModalTasks = ({ state, setState }) => {

    useEffect(() => {

    }, []);

    useEffect(() => {

    }, []);

    const closeModal = () => {
        setState(prev => ({
            ...prev,
            details: null,
            modal: false,
        }))
    }

    return (
        <Modal isOpen={state.modal} scrollable size='lg' data-cy="modal-0000">
            <ModalHeader toggle={closeModal}>
                Tarea: {state.details.name}
            </ModalHeader>
            <ModalBody>
                {
                    (state.details && Object.keys(state.details).length) && (
                        <DetailsTaskBasic data={state.details} />
                    )
                }
                {
                    (state.details.customer && Object.keys(state.details.customer).length) && (
                        <DetailsTaskCustomer data={state.details.customer} />
                    )
                }
                {
                    (state.details.property && Object.keys(state.details.property).length) && (
                        <DetailsTaskProperty data={state.details.property} />
                    )
                }
                {
                    (state.details.opportunity && Object.keys(state.details.opportunity).length) && (
                        <DetailsTaskChance data={state.details.opportunity} />
                    )
                }
            </ModalBody>
            <ModalFooter>
                <div className='buttons-modal-footer'>
                    <Button
                        disabled={state.loadingTable}
                        color="dark"
                        className=''
                        onClick={closeModal}
                        data-cy="close-modal-property-0000"
                    >
                        Cerrar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ModalTasks;