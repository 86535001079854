import axios from "axios";
import { toast } from 'react-toastify';
import { apiURL } from "../helpers/functions";
import { errorAction } from "../helpers/functions";
import { dataHeaders } from "../helpers/functions";
import { errorConsultingLastRecords, errorConsultingLastRecordsGraph, errorConsultingTasks } from "../constant/messages";

export const lastRegisterHomeAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/dashboard`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorConsultingLastRecords, { lastRecords: true }, 'dashboard'))
            });
    })
}

export const lastRegisterHomeChartAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/dashboard/chart`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorConsultingLastRecordsGraph, { lastRecordsGraph: true }, 'dashboard'))
            });
    })
}

export const lastTasksTodayAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/dashboard/tasks`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(errorAction(error, errorConsultingTasks, { tasks: true }, 'dashboard'))
            });
    })
}

export const loadDaysAllAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/offline/days`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(error);
                // reject(errorAction(error, errorConsultingLastRecords, { lastRecords: true }, 'dashboard'))
            });
    })
}

export const loadStreetsAllAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/offline/streets`, dataHeaders())
            .then((res) => {
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(error);
                // reject(errorAction(error, errorConsultingLastRecords, { lastRecords: true }, 'dashboard'))
            });
    })
}

export const loadPropertiesAllAction = (numberPage, resultsPage) => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/offline/properties?page=${numberPage}&items=${resultsPage}`, dataHeaders())
            .then((res) => {                
                if (!res.data.meta.success) {
                    toast.error(res.data.data[0], {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                    reject(res.data.data[0]);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                reject(error);
                // reject(errorAction(error, errorConsultingLastRecords, { lastRecords: true }, 'dashboard'))
            });
    })
}