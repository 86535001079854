import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Container, FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap'
import CardContainerNoAuth from './CardContainerNoAuth';
import { tokenConfirmPasswordAction, passwordConfirmAction } from '../api/login';
import Loader from '../layout/loader';
import { useError } from '../hooks/ErrorProvider';
import { thePasswordHasBeenChangedSuccessfully } from '../constant/messages';

const ConfirmPassword = () => {
    let { token } = useParams();
    const { stateError, setStateError } = useError()
    let navigate = useNavigate();
    const [loader, setLoader] = useState(true)
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState({
        value: "",
        error: false,
        messageError: ''
    });
    const [confirmPassword, setConfirmPassword] = useState({
        value: "",
        error: false,
        messageError: ''
    });
    const [togglePassword, setTogglePassword] = useState({ password: false, confirmPassword: false })

    useEffect(() => {
        tokenConfirmPasswordAction(
            token,
            () => { navigate(`/password-reset`) }
        )
            .then((res) => {
                setLoader(false)
            })
            .catch((res) => {
                setStateError({
                    ...stateError,
                    type: 'danger',
                    message: res,
                    show: true
                })
            })
    }, []);

    useEffect(() => {
        if (!password.value) {
            setTogglePassword({ ...togglePassword, password: false })
        }
    }, [password]);

    useEffect(() => {
        if (!confirmPassword.value) {
            setTogglePassword({ ...togglePassword, confirmPassword: false })
        }
    }, [confirmPassword]);

    const validate = (data) => {
        let acum = "";

        if (!data.password) {
            setPassword({ ...password, error: true, messageError: 'Debe introducir la contraseña' })
            acum = 1;
        }
        if (data.password && data.password.length < 8) {
            setPassword({ ...password, error: true, messageError: 'La contraseña debe tener al menos 8 caracteres' })
            acum = 1;
        }
        if (!data.confirmPassword) {
            setConfirmPassword({ ...confirmPassword, error: true, messageError: 'Debe introducir la confirmación de la contraseña' })
            acum = 1;
        }
        if (data.confirmPassword && (data.confirmPassword !== data.password)) {
            setConfirmPassword({ ...confirmPassword, error: true, messageError: 'La contraseña y la confirmacón deben coincidir' })
            acum = 1;
        }

        if (acum > 0) {
            return false;
        }
        return true;
    }


    const passwordConfirm = (data) => {
        const isValid = validate(data);
        if (isValid) {
            setLoading(true)
            passwordConfirmAction({ password: data.password, token: token })
                .then(() => {
                    // toast.success('La contraseña se ha cambiado correctamente.', {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    // });
                    setStateError({
                        ...stateError,
                        type: 'success',
                        message: thePasswordHasBeenChangedSuccessfully,
                        show: true
                    })
                    navigate(`/login`)
                    setLoading(false)
                })
                .catch((res) => {
                    setLoading(false)
                    setStateError({
                        ...stateError,
                        type: 'danger',
                        message: res,
                        show: true
                    })
                })

        }
    }


    return (

        <Container fluid={true} className="p-0" data-cy="login-container-0000">
            <ToastContainer data-cy="flash-message-login" />
            {
                loader ?
                    <Loader />
                    :
                    <CardContainerNoAuth>
                        <>
                            <p>Introduzca la contraseña</p>
                            <p></p>
                            <FormGroup className='position-relative'>
                                <Label className="col-form-label">Contraseña</Label>
                                <Input
                                    className="form-control"
                                    type={togglePassword.password ? "text" : "password"}
                                    onChange={e => setPassword({ ...password, value: e.target.value, error: false })}
                                    value={password.value}
                                    id="new_password"
                                    name='new_password'
                                    required=""
                                    invalid={password.error}
                                    autoComplete='new_password'
                                    data-cy="input-password-login-0000"
                                />
                                <FormFeedback data-cy="error-input-password-login-0000">
                                    {
                                        password.error ? password.messageError : ''
                                    }
                                </FormFeedback>

                                <div className="show-hide" onClick={() => setTogglePassword({ ...togglePassword, password: !togglePassword.password })}>
                                    {
                                        password.value ?
                                            <span className={togglePassword.password ? "" : "show"}></span>
                                            :
                                            null
                                    }
                                </div>
                            </FormGroup>
                            <FormGroup className='position-relative'>
                                <Label className="col-form-label">Confirmar contraseña</Label>
                                <Input
                                    className="form-control"
                                    type={togglePassword.confirmPassword ? "text" : "password"}
                                    onChange={e => setConfirmPassword({ ...password, value: e.target.value, error: false })}
                                    value={confirmPassword.value}
                                    id="re_new_password"
                                    name='re_new_password'
                                    required=""
                                    invalid={confirmPassword.error}
                                    autoComplete='re_new_password'
                                    data-cy="input-password-login-0000"
                                />
                                <FormFeedback data-cy="error-input-password-login-0000">
                                    {
                                        confirmPassword.error ? confirmPassword.messageError : ''
                                    }
                                </FormFeedback>

                                <div className="show-hide" onClick={() => setTogglePassword({ ...togglePassword, confirmPassword: !togglePassword.confirmPassword })}>
                                    {
                                        confirmPassword.value ?
                                            <span className={togglePassword.confirmPassword ? "" : "show"}></span>
                                            :
                                            null
                                    }
                                </div>
                            </FormGroup>
                            <Button
                                color="primary"
                                className="col-12"
                                onClick={() => passwordConfirm({ password: password.value, confirmPassword: confirmPassword.value })}
                                data-cy="button-login-0000"
                                disabled={loading}
                            >
                                {
                                    loading ?
                                        <div className="loader-box" style={{ height: 'auto' }}>
                                            <div
                                                className="loader-2"
                                                style={{ height: '25px', width: '25px', borderLeftColor: 'white', borderRightColor: 'white' }}
                                            />
                                        </div>
                                        :
                                        'Enviar'
                                }
                            </Button>
                        </>
                    </CardContainerNoAuth>
            }
        </Container>
    )
}

export default ConfirmPassword