import React from 'react'

const PrivacyPolicies = () => {
  return (
    <div className='privacy-policies'>
      <h3>Política de privacidad</h3>
      <p>Política de Privacidad de la Aplicación "Registro de Zona"</p>
      <p>Fecha de última actualización: 14/02/2024</p>
      <p>
        Bienvenido a la aplicación "Registro de Zona" desarrollada por Grocasa. Esta Política de Privacidad tiene
        como objetivo informarte sobre cómo recopilamos, utilizamos y protegemos la información que obtengamos a
        través de esta aplicación. Al utilizar la aplicación "Registro de Zona", aceptas las prácticas descritas
        en esta política.
      </p>
      <ol>
        <li>
          Información Recopilada:
          <ul>
            <li>
              Datos de Acceso: La aplicación requiere que los usuarios realicen un inicio de sesión para acceder.
              La información de inicio de sesión se limita a los empleados de Grocasa y se utiliza exclusivamente
              para garantizar la seguridad y la autenticación de los usuarios.
            </li>
          </ul>
        </li>
        <li>
          Uso de la Información:
          <ul>
            <li>
              Acceso Restringido: El acceso a la aplicación se otorga únicamente a los empleados de Grocasa.
              La información recopilada se utiliza para garantizar la autenticidad del usuario y proporcionar
              una experiencia personalizada dentro de la aplicación.
            </li>
          </ul>
        </li>
        <li>
          GPS y Localización:
          <ul>
            <li>
              La aplicación "Registro de Zona" no utiliza la función de GPS ni recopila datos de ubicación.
              La privacidad de los usuarios es una prioridad, y la aplicación se centra en la información
              esencial para el uso interno de Grocasa.
            </li>
          </ul>
        </li>
        <li>
          Contacto para Preguntas o Dudas:
          <ul>
            <li>
              Cualquier pregunta o duda relacionada con la privacidad y el uso de la aplicación puede dirigirse
              a la dirección de correo electrónico zona@grocasa.com. Estamos comprometidos a responder de manera
              oportuna y abordar cualquier inquietud que puedas tener.
            </li>
          </ul>
        </li>
        <li>
          Procedimientos Seguros:
          <ul>
            <li>
              Cifrado de Datos: Todos los datos transmitidos entre la aplicación y los servidores de Grocasa
              se cifran utilizando protocolos seguros para proteger la confidencialidad de la información.
            </li>
            <li>
              Acceso Restringido: El acceso a los datos está limitado a los empleados autorizados de Grocasa,
              y se implementan medidas de seguridad para prevenir el acceso no autorizado.
            </li>
            <li>
              Actualizaciones de Seguridad: Se realizan actualizaciones periódicas de seguridad para proteger
              contra posibles vulnerabilidades y amenazas.
            </li>
          </ul>
        </li>
        <li>
          Conservación y Eliminación de Datos:
          <ul>
            <li>
              Conservación Limitada: Los datos de usuario se conservan durante el tiempo necesario para cumplir
              con los propósitos establecidos en la aplicación, y se eliminan cuando ya no son necesarios.
            </li>
            <li>
              Solicitud de Eliminación: Los usuarios tienen el derecho de solicitar la eliminación de sus datos
              personales, y el desarrollador responderá a estas solicitudes de manera oportuna.
            </li>
            <li>
              Política de Retención Documentada: Se mantiene una política de retención de datos documentada que
              establece los plazos específicos para la conservación y eliminación de datos.
            </li>
          </ul>
        </li>
        <li>
          Actualizaciones de la Política de Privacidad:
          <ul>
            <li>
              Nos reservamos el derecho de actualizar esta política en cualquier momento. Se te notificará
              sobre cualquier cambio significativo mediante la aplicación o a través de la dirección de correo
              electrónico proporcionada durante el registro.
            </li>
          </ul>
        </li>
        <li>
          Consentimiento:
          <ul>
            <li>
              Al utilizar la aplicación "Registro de Zona", aceptas los términos y condiciones establecidos
              en esta Política de Privacidad.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Te agradecemos por confiar en la aplicación "Registro de Zona" desarrollada por Grocasa. Estamos comprometidos
        a proteger tu privacidad y a proporcionar una experiencia segura y eficiente para todos los usuarios.
      </p>
    </div>
  )
}

export default PrivacyPolicies