import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Label,
    Table,
    Alert,
    Button
} from 'reactstrap'
import { selectStreetsAction, propertiesStreetsAction, selectNumberStreetAction } from '../../../api/propertyAddress';
import Select from 'react-select';
import {
    formAddress,
    colorRowTableStatus,
    paylodadEndPointSearchProperty,
    openModal,
    saveRequestSearch,
    existModeOffLine
} from '../../../helpers/functions';
import { stateInitital } from '../state/StateProperties'
import ModalDetails from '../Modals/ModalDetails';
import ModalFilters from '../Modals/ModalFilters';
import ModalOwner from '../Modals/ModalOwner';
import { thereAreNoAssociatedRecords } from '../../../constant/messages';

const PropertyAddress = (props) => {
    const filterBasics = ['street_id', 'number']
    const [state, setState] = useState(stateInitital)

    useEffect(() => {
        selectStreetsAction()
            .then((res) => {
                if (res.data.data.streets.length) {
                    let newData = [];
                    res.data.data.streets.map(street => {
                        newData.push({ label: street.name, value: street.id_crm })
                    })
                    setState(prev => ({ ...prev, dataSelectStreets: newData, loading: false }))
                    saveRequestSearch(
                        'address',
                        {
                            params: { streets: true },
                            data: newData,
                        }
                    )
                }
            })
            .catch((error) => {
                if (error && error.data) {
                    setState(prev => ({ ...prev, loading: false, dataSelectStreets: existModeOffLine() ? error.data : error.data.data }))
                } else {
                    setState(prev => ({ ...prev, loading: false, dataSelectStreets: [] }))
                }
            })
    }, []);

    const handleChangeStreets = (value) => {
        setState(prev => ({
            ...prev,
            loadingTable: true,
            street_id: value,
            number: null
        }))

        if (value) {
            propertiesStreetsAction(
                paylodadEndPointSearchProperty(state, [], { name: 'street_id', value: value.value }).payload
            )
                .then((res) => {
                    setState(prev => ({ ...prev, data: res.data.data.properties, loadingTable: false }))
                    selectNumberStreetAction(value.value)
                        .then((resNumber) => {
                            setState(prev => ({ ...prev, dataSelectNumbers: loadSelectNumberStreeets(resNumber.data.data.numbers) }))
                            saveRequestSearch(
                                'address',
                                {
                                    params: paylodadEndPointSearchProperty(state, [], { name: 'street_id', value: value.value }).payload,
                                    data: res.data.data.properties,
                                    numbers: loadSelectNumberStreeets(resNumber.data.data.numbers)
                                }
                            )
                        })
                        .catch(() => {
                            setState(prev => ({ ...prev, dataSelectNumbers: [] }))
                        })
                })
                .catch((error) => {
                    if (error && error.data) {
                        setState(prev => ({
                            ...prev,
                            data: existModeOffLine() ? error.data : error.data.data,
                            dataSelectNumbers: existModeOffLine() ? validateDataStreetsNumber(value) : error.data.numbers,
                            loadingTable: false,
                            errorMessage: error.message
                        }))
                    } else {
                        setState(prev => ({ ...prev, data: null, loadingTable: false, errorMessage: error.message || '' }))
                    }
                })
        } else {
            setState(prev => ({
                ...prev,
                data: null,
                loadingTable: false,
                number: null,
                dataSelectNumbers: [],
                occupied_by: { label: 'Todos', value: 'todos' },
                contacted: { label: 'Todos', value: 'todos' },
                status: { label: 'Todos', value: 'todos' },
                stage_opc: { label: 'Todas', value: 'todos' },
                has_active_ope: { label: 'Todos', value: 'todos' },
                errorMessage: ''
            }))
        }
    }

    const handleChangeNumberStreet = (value) => {
        setState(prev => ({ ...prev, loadingTable: true, number: value }))

        if (value) {
            propertiesStreetsAction(
                paylodadEndPointSearchProperty(state, filterBasics, { name: 'number', value: value.value }).payload
            )
                .then((res) => {
                    setState(prev => ({ ...prev, data: res.data.data.properties, loadingTable: false }))
                    saveRequestSearch(
                        'address',
                        {
                            params: paylodadEndPointSearchProperty(state, filterBasics, { name: 'number', value: value.value }).payload,
                            data: res.data.data.properties,
                            numbers: state.dataSelectNumbers
                        }
                    )
                })
                .catch((error) => {
                    if (error && error.data) {
                        setState(prev => ({
                            ...prev,
                            data: existModeOffLine() ? error.data : error.data.data,
                            errorMessage: error.message,
                            loadingTable: false
                        }))
                    } else {
                        setState(prev => ({ ...prev, data: null, loadingTable: false, errorMessage: error.message || '' }))
                    }
                })
        } else {
            if (state.street_id && state.street_id.value) {
                propertiesStreetsAction(
                    paylodadEndPointSearchProperty(state, [], { name: 'street_id', value: state.street_id.value }).payload
                )
                    .then((res) => {
                        setState(prev => ({ ...prev, data: res.data.data.properties }))
                        selectNumberStreetAction(state.street_id.value)
                            .then((resNumber) => {
                                setState(prev => ({ ...prev, dataSelectNumbers: loadSelectNumberStreeets(resNumber.data.data.numbers) }))
                                saveRequestSearch(
                                    'address',
                                    {
                                        params: paylodadEndPointSearchProperty(state, [], { name: 'street_id', value: state.street_id.value }).payload,
                                        data: res.data.data.properties,
                                        numbers: loadSelectNumberStreeets(resNumber.data.data.numbers)
                                    }
                                )
                            })
                            .catch(() => {
                                setState(prev => ({ ...prev, dataSelectNumbers: [] }))
                            })
                            .finally(() => {
                                setState(prev => ({ ...prev, loadingTable: false }))
                            })
                    })
                    .catch((error) => {
                        if (error && error.data) {
                            setState(prev => ({
                                ...prev,
                                data: existModeOffLine() ? error.data : error.data.data,
                                dataSelectNumbers: existModeOffLine() ? validateDataStreetsNumber(state.street_id) : error.data.numbers,
                                errorMessage: error.message,
                                loadingTable: false
                            }))
                        } else {
                            setState(prev => ({ ...prev, data: null, loadingTable: false, errorMessage: error.message || '' }))
                        }
                    })
            }
        }
    }

    const loadSelectNumberStreeets = (data) => {
        let arrayNumberStreets = [];
        data.map(day => {
            arrayNumberStreets.push({ label: day, value: day })
        })
        if (data.length === 1) {
            setState(prev => ({ ...prev, number: arrayNumberStreets[0] }))
        }
        return arrayNumberStreets;
    }

    const validateDataStreetsNumber = (data) => {
        if (data.numbers) return data.numbers

        if (localStorage.getItem('dataOffLine')) {
            let objOffLine = JSON.parse(localStorage.getItem('dataOffLine'));
            if (objOffLine.streets) {
                return objOffLine.streets.find(item => item.value === data.value).numbers
            }
        }

        return []
    }

    return (
        <Fragment>
            <Breadcrumb parent="Inmuebles por dirección" title="Inmuebles por dirección" />
            <Container fluid={true}>
                {
                    (state.modal && state.details) ?
                        <ModalDetails
                            state={state}
                            setState={setState}
                        />
                        :
                        <></>
                }
                {
                    state.modalFilters ?
                        <ModalFilters
                            state={state}
                            setState={setState}
                            filterBasics={filterBasics}
                            action={propertiesStreetsAction}
                            module='address'
                        />
                        :
                        <></>
                }
                {
                    state.modalOwner ?
                        <ModalOwner
                            state={state}
                            setState={setState}
                        />
                        :
                        <></>
                }
                <Row>
                    {
                        state.loading ?
                            <Col>
                                <div className="loader-box">
                                    <div className="loader-9"></div>
                                </div>
                            </Col>
                            :
                            <Col sm="12" data-cy="col-properties-for-address-0000" className='px-0'>
                                <Card data-cy="card-properties-for-address-0000" className='card-table'>
                                    <CardHeader style={{ paddingBottom: '20px' }} data-cy="card-header-properties-for-address-0000">
                                        <Row className='justify-content-end' data-cy="row-filters-search-address-0000">
                                            <Col sm="12" lg="5" data-cy="col-filter-address-0000">
                                                <FormGroup row>
                                                    <Label sm={3} htmlFor="daysSelect">Calle</Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            value={state.street_id}
                                                            onChange={handleChangeStreets}
                                                            options={state.dataSelectStreets}
                                                            placeholder='Seleccione...'
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            noOptionsMessage={() => 'Sin registros...'}
                                                            isDisabled={state.loadingTable}
                                                            isLoading={state.loadingTable}
                                                            inputId="filterForStreet"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" lg="5">
                                                <FormGroup row>
                                                    <Label sm={3} htmlFor="daysSelect">Número</Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            value={state.number}
                                                            onChange={handleChangeNumberStreet}
                                                            options={state.dataSelectNumbers}
                                                            placeholder='Seleccione...'
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            noOptionsMessage={() => 'Sin registros...'}
                                                            isDisabled={state.loadingTable}
                                                            isLoading={state.loadingTable}
                                                            inputId="filterForNumberStreet"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" md="3" lg="2">
                                                <Button
                                                    disabled={(!state.data && paylodadEndPointSearchProperty(state, filterBasics).filtersOthers === 0) ? true : false}
                                                    className='col-12 px-0'
                                                    color="primary"
                                                    onClick={() => setState(prev => ({
                                                        ...prev,
                                                        modalFilters: true,
                                                    }))}
                                                    data-cy="open-modal-more-filters-0000"
                                                >
                                                    {
                                                        paylodadEndPointSearchProperty(state, filterBasics).filtersOthers === 0 ?
                                                            'Más filtros...' :
                                                            `Más filtros (${paylodadEndPointSearchProperty(state, filterBasics).filtersOthers})`
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {
                                                state.loadingTable ?
                                                    <Col>
                                                        <div className="loader-box">
                                                            <div className="loader-9"></div>
                                                        </div>
                                                    </Col>
                                                    :
                                                    <>
                                                        {
                                                            state.data ?
                                                                <Col sm="12" className='mb-4 px-2'>
                                                                    <h6>Inmuebles encontrados: {state.data.length}</h6>
                                                                </Col>
                                                                :
                                                                null
                                                        }
                                                        <Col xs="12" className='px-0'>
                                                            {
                                                                state.data ?
                                                                    <>
                                                                        {
                                                                            state.data.length ?
                                                                                <Table responsive data-cy="table-results-for-address-0000">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: '10%' }}>Ref.</th>
                                                                                            <th scope="col" style={{ width: '15%' }}>Ocupado por</th>
                                                                                            <th scope="col" style={{ width: '40%' }}>Dirección</th>
                                                                                            <th className="d-none d-lg-table-cell" scope="col" style={{ width: '15%' }}>Estado</th>
                                                                                            <th className="d-none d-lg-table-cell" scope="col" style={{ width: '15%' }}>Días sin contactar</th>
                                                                                            <th scope="col" style={{ width: '5%' }}>Acciones</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            state.data.map((property, key) => {
                                                                                                return (
                                                                                                    <tr key={key} className={`${colorRowTableStatus(property.status)}`}>
                                                                                                        <td
                                                                                                            className='text-primary'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            title='Ver detalles'

                                                                                                        >
                                                                                                            <div
                                                                                                                data-cy="open-modal-view-details-0000"
                                                                                                                onClick={() => openModal(property, 'details', setState)}
                                                                                                            >
                                                                                                                {property.reference}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td className='small-font-td'>
                                                                                                            {property.occupied_by}
                                                                                                        </td>
                                                                                                        <td className='small-font-td'>
                                                                                                            {formAddress(property)}
                                                                                                        </td>
                                                                                                        <td className="d-none d-lg-table-cell">
                                                                                                            {property.status}
                                                                                                        </td >
                                                                                                        <td className="d-none d-lg-table-cell">
                                                                                                            {property.days_last_contact}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Button
                                                                                                                color="primary"
                                                                                                                className='col-12 px-3 w-auto'
                                                                                                                onClick={() => openModal(property, 'update', setState)}
                                                                                                                title="Editar inmueble"
                                                                                                                data-cy="open-modal-edit-0000"
                                                                                                            >
                                                                                                                <i className="fa fa-edit fa-lg mx-1" style={{ cursor: 'pointer' }} />
                                                                                                            </Button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </Table>

                                                                                :
                                                                                <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                                                                    <p>{thereAreNoAssociatedRecords}</p>
                                                                                </Alert>
                                                                        }
                                                                    </>
                                                                    :
                                                                    state.errorMessage ?
                                                                        <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                                                            <p>{state.errorMessage}</p>
                                                                        </Alert>
                                                                        :
                                                                        null
                                                            }
                                                        </Col>
                                                    </>
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                    }
                </Row>
            </Container>
            <style>
                {
                    `
                    @media (max-width: 576px) {
                        .modal-dialog {
                            margin:0;                        
                        }
                        .modal-dialog-scrollable .modal-content {
                            max-height: 100vh
                        }
                        .modal-content {
                            height: 100vh
                        }
                    }
                    `
                }
            </style>
        </Fragment>
    )
}

export default PropertyAddress