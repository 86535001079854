import React from "react";
import {
    Container,
    Col,
    FormGroup,
    Label,
    Input
} from 'reactstrap'
import Select from 'react-select';
import { dataOccupiedBy } from "../../../helpers/functions";

const UpdateProperty = ({
    setState,
    state,
}) => {

    const handleChangeOccupìedBy = (value) => {
        if (value) {
            setState(prev => ({ ...prev, occupiedBy: value }))
        } else {
            setState(prev => ({ ...prev, occupiedBy: null }))
        }
    }

    const handleChangeComment = (e) => {
        setState(prev => ({ ...prev, comment: e.target.value.replace('  ', ' ') }))
    }

    return (
        <>
            <FormGroup row>
                <Label sm={3}>
                    Dirección
                </Label>
                <Label sm={9}>
                    {state.address}
                </Label>
            </FormGroup>
            <FormGroup row>
                <Label
                    for="comment"
                    sm={3}
                >
                    Ocupado por
                </Label>
                <Col sm={9}>
                    <Select
                        value={state.occupiedBy}
                        onChange={handleChangeOccupìedBy}
                        options={dataOccupiedBy}
                        placeholder='Seleccione...'
                        isSearchable={true}
                        inputId="occupiedByEdit"
                        menuPosition="fixed"
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label
                    for="comment"
                    sm={3}
                >
                    Comentario
                </Label>
                <Col sm={9}>
                    <Input
                        value={state.comment}
                        id="comment"
                        name="text"
                        type="textarea"
                        onChange={handleChangeComment}
                        maxLength={500}
                        rows={5}
                        data-cy="comment-property-edit"
                    />
                </Col>

            </FormGroup>
            <FormGroup row>
                <Col className="offset-sm-3 text-left mt-n3 text-muted">
                    <p className="text-nowrap">
                        Minimo 10 carácteres
                    </p>
                </Col>
                <Col className="text-right mt-n3 text-muted">
                    <p>
                        {`${state.comment.length} / 500`}
                    </p>
                </Col>
            </FormGroup>
            <style>
                {
                    `
                        textarea {
                            resize: none !important;
                            font-size: 0.8rem !important;
                        }
                    `
                }
            </style>
        </>
    )
}

export default UpdateProperty