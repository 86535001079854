import axios from "axios";
import { toast } from 'react-toastify';
import { apiURL } from "../helpers/functions";
import { errorAction } from "../helpers/functions";
import { dataHeaders } from "../helpers/functions";
import { errorConsultingPendingTasks } from "../constant/messages";

export const pendingTasksAction = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiURL()}/api/app/register/dashboard/tasks/pending`, dataHeaders())
        .then((res) => {
            if (!res.data.meta.success) {
                toast.error(res.data.data[0], {
                    position: "top-right",
                    autoClose: 5000,
                    theme: "colored",
                });
                reject(res.data.data[0]);
            } else {
                resolve(res);
            }
        })
        .catch((error) => {
            reject(errorAction(error, errorConsultingPendingTasks, { pendingTasks: true }, 'pending-tasks'))
        });
    })
}