import React, { useState, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap'
import ModalPrivacyPolicies from '../../components/application/Modals/ModalPrivacyPolicies';

const Footer = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      {
        open && (
          <ModalPrivacyPolicies open={open} setOpen={setOpen} />
        )
      }
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <p
                className="mb-0"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen(true)}
              >
                Políticas de privacidad
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}

export default Footer;