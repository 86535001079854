import React from 'react';

const Contact = ({ phone }) => {
    return (
        <div data-cy="container-contact-0000">
            <a
                className="btn btn-success"
                href={`tel:${phone}`}
                type="button"
            >
                Llamar
            </a>
            <a
                className="btn btn-success"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=34${phone}&text=Hola!`}
                type="button"
            >
                WhatsApp
            </a>
        </div>
    );
}

export default Contact;