import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify'
import { Container, FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap'
import CardContainerNoAuth from './CardContainerNoAuth';
import { validateEmail } from '../helpers/functions';
import { passwordResetAction } from '../api/login';
import { useError } from '../hooks/ErrorProvider';
import { weHaveSentYouALinkFromWhereToChangeYourPassword } from '../constant/messages';
import { useNavigate } from 'react-router-dom'

const ResetPassword = (props) => {
    let navigate = useNavigate();
    const { stateError, setStateError } = useError()
    const [loading, setLoading] = useState(false)
    const [emailSend, setEmailSend] = useState(false)
    const [email, setEmail] = useState({
        value: "",
        error: false,
        messageError: ''
    });

    const validate = (data) => {
        let acum = "";

        if (data.email === '') {
            setEmail({ ...email, error: true, messageError: 'Debe ingresar el email' })
            acum = 1;
        }
        if (data.email !== '' && !validateEmail(data.email)) {
            setEmail({ ...email, error: true, messageError: 'Email incorrecto' })
            acum = 1;
        }
        if (acum > 0) {
            return false;
        }
        return true;
    }


    const passwordReset = (data) => {
        const isValid = validate(data);
        if (isValid) {
            setLoading(true)
            passwordResetAction({ email: data.email })
                .then(() => {
                    // toast.success('Te hemos enviado un enlace desde donde cambiar tu contraseña', {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    // });
                    setStateError({
                        ...stateError,
                        type: 'success',
                        message: weHaveSentYouALinkFromWhereToChangeYourPassword,
                        show: true
                    })
                    setLoading(false)
                    setEmailSend(true)
                })
                .catch((res) => {
                    setLoading(false)
                    setStateError({
                        ...stateError,
                        type: 'danger',
                        message: res,
                        show: true
                    })
                })
        }
    }


    return (
        <Container fluid={true} className="p-0" data-cy="login-container-0000">
            <ToastContainer data-cy="flash-message-login" />
            <CardContainerNoAuth>
                {
                    !emailSend ?
                        <>
                            <p>Introduce tu e-mail y te ayudaremos a cambiarla.<br />Recibiras un e-mail con instrucciones.</p>
                            <p></p>
                            <FormGroup>
                                <Label className="col-form-label">Email</Label>
                                <Input
                                    className="form-control"
                                    type="email"
                                    error='true'
                                    onChange={e => setEmail({ ...email, value: e.target.value, error: false })}
                                    value={email.value}
                                    id="email"
                                    invalid={email.error}
                                    data-cy="input-email-login-0000"
                                />
                                <FormFeedback data-cy="error-input-email-login-0000">
                                    {
                                        email.error ? email.messageError : ''
                                    }
                                </FormFeedback>
                            </FormGroup>
                            <Button
                                color="primary"
                                className="col-12 mb-2"
                                onClick={() => passwordReset({ email: email.value })}
                                data-cy="button-login-0000"
                                disabled={loading}
                            >
                                {
                                    loading ?
                                        <div className="loader-box" style={{ height: 'auto' }}>
                                            <div
                                                className="loader-2"
                                                style={{ height: '25px', width: '25px', borderLeftColor: 'white', borderRightColor: 'white' }}
                                            />
                                        </div>
                                        :
                                        'Enviar'
                                }
                            </Button>
                        </>
                        :
                        null
                }
                <Button
                    color="dark"
                    className="col-12"
                    onClick={() => navigate(`/login`)}
                    data-cy="button-back-login-0000"
                >
                    Ir a inicio de sesión
                </Button>
            </CardContainerNoAuth>
        </Container>
    )
}

export default ResetPassword