import React, { Fragment } from 'react';
import sad from '../../assets/images/other-images/sad.png';
import { Container, Button, Media, Col } from "reactstrap"
import { getMobileOperatingSystem } from '../../helpers/functions';

const ErrorIncompatibleVersion = () => {

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper">
                    <Container>
                        <Media body className="img-100" src={sad} alt="" />
                        <div className="error-heading">
                        </div>
                        <Col md="8 offset-md-2">
                            <p className="sub-content">{"Esta versión está desactualizada, para que puedas seguir usando registro de zona, y para que podamos ofrecerte la mejor experiencia posible, actualiza tu app."}</p>
                        </Col>
                        <Button
                            onClick={() => window.location.href = getMobileOperatingSystem().url}
                            color="primary"
                            size='lg'
                        >
                            Actualizar App
                        </Button>
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default ErrorIncompatibleVersion;