import React, { useState } from "react";

const ErrorContext = React.createContext();

export function ErrorProvider(props) {
    const [stateError, setStateError] = useState({
        show: false,
        message: '',
        type: ''
    });

    return (
        <ErrorContext.Provider
            value={{
                stateError,
                setStateError
            }}
            {...props}
        />
    );
}

export function useError() {
    const context = React.useContext(ErrorContext);
    if (!context) {
        throw new Error("useError debe estar dentro del proveedor ErrorContext");
    }
    return context;
}
