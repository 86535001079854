import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Label,
    Table,
    Alert,
    Button,
} from 'reactstrap'
import {
    selectDaysAction,
    propertiesDaysAction,
    selectStreetsNumberAction,
} from '../../../api/propertyDayZone';
import Select from 'react-select';
import {
    formAddress,
    colorRowTableStatus,
    paylodadEndPointSearchProperty,
    openModal,
    saveRequestSearch,
    existModeOffLine,
} from '../../../helpers/functions';
import { stateInitital } from '../state/StateProperties'
import ModalFilters from '../Modals/ModalFilters';
import ModalDetails from '../Modals/ModalDetails';
import ModalOwner from '../Modals/ModalOwner';
import { thereAreNoAssociatedRecords } from '../../../constant/messages';

const PropertyDayZone = () => {
    const filterBasics = ['day', 'street_id']
    const [state, setState] = useState(stateInitital)

    useEffect(() => {
        selectDaysAction()
            .then((res) => {
                if (res.data.data.days.length) {
                    let newData = [];
                    res.data.data.days.map(day => {
                        newData.push({ label: day.name, value: day.value })
                    })
                    setState(prev => ({ ...prev, dataSelectDays: newData, loading: false }))
                    saveRequestSearch(
                        'day-zone',
                        {
                            params: { days: true },
                            data: newData,
                        }
                    )
                }
            })
            .catch((error) => {
                if (error && error.data) {
                    setState(prev => ({ ...prev, loading: false, dataSelectDays: existModeOffLine() ? error.data : error.data.data }))
                } else {
                    setState(prev => ({ ...prev, loading: false, dataSelectDays: [] }))
                }
            })
    }, []);

    const handleChangeDay = (value) => {
        setState(prev => ({ ...prev, loadingTable: true, day: value, street_id: null }))
        if (value) {
            propertiesDaysAction(
                paylodadEndPointSearchProperty(state, [], { name: 'day', value: value.value }).payload
            )
                .then((res) => {
                    setState(prev => ({ ...prev, data: res.data.data.properties, loadingTable: false }))
                    selectStreetsNumberAction({ day: value.value })
                        .then((resStreet) => {
                            setState(prev => ({ ...prev, dataSelectStreets: loadSelectStreets(resStreet.data.data.streets) }))
                            saveRequestSearch(
                                'day-zone',
                                {
                                    params: paylodadEndPointSearchProperty(state, [], { name: 'day', value: value.value }).payload,
                                    data: res.data.data.properties,
                                    streets: loadSelectStreets(resStreet.data.data.streets)
                                }
                            )
                        })
                        .catch(() => {
                            setState(prev => ({ ...prev, dataSelectStreets: [] }))
                        })
                })
                .catch((error) => {
                    if (error && error.data) {
                        setState(prev => ({
                            ...prev,
                            data: existModeOffLine() ? error.data : error.data.data,
                            dataSelectStreets: existModeOffLine() ? validateDataStreetsDay(value) : error.data.streets,
                            //street_id: loadStreetDataOne(existModeOffLine() ? value.streets : error.data.streets),
                            loadingTable: false,
                            errorMessage: error.message
                        }))
                    } else {
                        setState(prev => ({ ...prev, data: null, loadingTable: false, errorMessage: error.message || '' }))
                    }
                })
        } else {
            setState(prev => ({
                ...prev,
                data: null,
                loadingTable: false,
                street_id: null,
                dataSelectStreets: [],
                occupied_by: { label: 'Todos', value: 'todos' },
                contacted: { label: 'Todos', value: 'todos' },
                status: { label: 'Todos', value: 'todos' },
                stage_opc: { label: 'Todas', value: 'todos' },
                has_active_ope: { label: 'Todos', value: 'todos' },
                errorMessage: ''
            }))
        }
    }

    const handleChangeStreet = (value) => {
        setState(prev => ({ ...prev, loadingTable: true, street_id: value }))

        if (value) {
            propertiesDaysAction(
                paylodadEndPointSearchProperty(state, filterBasics, { name: 'street_id', value: value.value }).payload
            )
                .then((res) => {
                    setState(prev => ({ ...prev, data: res.data.data.properties, loadingTable: false }))
                    saveRequestSearch(
                        'day-zone',
                        {
                            params: paylodadEndPointSearchProperty(state, filterBasics, { name: 'street_id', value: value.value }).payload,
                            streets: state.dataSelectStreets,
                            data: res.data.data.properties
                        }
                    )
                })
                .catch((error) => {
                    if (error && error.data) {
                        setState(prev => ({
                            ...prev,
                            data: existModeOffLine() ? error.data : error.data.data,
                            errorMessage: error.message,
                            loadingTable: false
                        }))
                    } else {
                        setState(prev => ({ ...prev, data: null, loadingTable: false, errorMessage: error.message || '' }))
                    }
                })
        } else {
            propertiesDaysAction(
                paylodadEndPointSearchProperty(state, [], { name: 'day', value: state.day.value }).payload || { day: state.day.value }
            )
                .then((res) => {
                    setState(prev => ({ ...prev, data: res.data.data.properties }))
                    selectStreetsNumberAction({ day: state.day.value })
                        .then((resStreets) => {
                            setState(prev => ({ ...prev, dataSelectStreets: loadSelectStreets(resStreets.data.data.streets) }))
                            saveRequestSearch(
                                'day-zone',
                                {
                                    params: paylodadEndPointSearchProperty(state, [], { name: 'day', value: state.day.value }).payload,
                                    data: res.data.data.properties,
                                    streets: loadSelectStreets(resStreets.data.data.streets)
                                }
                            )

                        })
                        .catch(() => {
                            setState(prev => ({ ...prev, dataSelectStreets: [] }))
                        })
                        .finally(() => {
                            setState(prev => ({ ...prev, loadingTable: false }))
                        })

                })
                .catch((error) => {
                    if (error && error.data) {
                        setState(prev => ({
                            ...prev,
                            data: existModeOffLine() ? error.data : error.data.data,
                            dataSelectStreets: existModeOffLine() ? validateDataStreetsDay(state.day) : error.data.streets,
                            //street_id: loadStreetDataOne(existModeOffLine() ? state.day.streets : error.data.streets),
                            errorMessage: error.message,
                            loadingTable: false
                        }))
                    } else {
                        setState(prev => ({ ...prev, data: null, loadingTable: false, errorMessage: error.message || '' }))
                    }
                })
        }
    }

    const loadSelectStreets = (data) => {
        let arrayStreets = [];
        data.map(street => {
            arrayStreets.push({ label: street.name, value: street.id_crm })
        })
        if (data.length === 1) {
            setState(prev => ({ ...prev, street_id: arrayStreets[0] }))
        }
        return arrayStreets;
    }

    const loadStreetDataOne = (data) => {
        if (data?.length === 1) {
            return data[0]
        }
        return null
    }

    const validateDataStreetsDay = (data) => {
        if (data.streets) return data.streets

        if (localStorage.getItem('dataOffLine')) {
            let objOffLine = JSON.parse(localStorage.getItem('dataOffLine'));
            if (objOffLine.days) {
                return objOffLine.days.find(item => item.value === data.value).streets
            }
        }

        return []
    }

    return (
        <Fragment>
            <Breadcrumb parent="Inmuebles por día de zona" title="Inmuebles por dia de zona" />
            <Container fluid={true} data-cy="container-search-day-zona-0000">
                {
                    (state.modal && state.details) ?
                        <ModalDetails
                            state={state}
                            setState={setState}
                        />
                        :
                        <></>
                }
                {
                    state.modalFilters ?
                        <ModalFilters
                            state={state}
                            setState={setState}
                            filterBasics={filterBasics}
                            action={propertiesDaysAction}
                            module='day-zone'
                        />
                        :
                        <></>
                }
                {
                    state.modalOwner ?
                        <ModalOwner
                            state={state}
                            setState={setState}
                        />
                        :
                        <></>
                }
                <Row>
                    {
                        state.loading ?
                            <Col>
                                <div className="loader-box">
                                    <div className="loader-9"></div>
                                </div>
                            </Col>
                            :
                            <Col sm="12" data-cy="col-properties-for-day-0000" className='px-0'>
                                <Card data-cy="card-properties-for-day-0000" className='card-table'>
                                    <CardHeader style={{ paddingBottom: '20px' }} data-cy="card-header-properties-for-day-0000">
                                        <Row className='justify-content-end' data-cy="row-filters-search-day-zone-0000">
                                            <Col sm="12" lg="5" data-cy="col-filter-day-0000" >
                                                <FormGroup row>
                                                    <Label sm={3} htmlFor="daysSelect">Día</Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            value={state.day}
                                                            onChange={handleChangeDay}
                                                            options={state.dataSelectDays}
                                                            placeholder='Seleccione...'
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            data-cy="select-0000"
                                                            inputId='filterForDay'
                                                            isDisabled={state.loadingTable}
                                                            isLoading={state.loadingTable}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" lg="5">
                                                <FormGroup row>
                                                    <Label sm={3} htmlFor="streetSelect">Calle</Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            value={state.street_id}
                                                            onChange={handleChangeStreet}
                                                            options={state.dataSelectStreets}
                                                            placeholder='Seleccione...'
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            noOptionsMessage={() => 'Sin registros...'}
                                                            isDisabled={state.loadingTable}
                                                            isLoading={state.loadingTable}
                                                            data-cy="select-0000"
                                                            inputId='filterForStreet'
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" md="3" lg="2">
                                                <Button
                                                    disabled={(!state.data && paylodadEndPointSearchProperty(state, filterBasics).filtersOthers === 0) ? true : false}
                                                    className='col-12 px-0'
                                                    color="primary"
                                                    onClick={() => setState(prev => ({
                                                        ...prev,
                                                        modalFilters: true,
                                                    }))}
                                                    data-cy="open-modal-more-filters-0000"
                                                >
                                                    {
                                                        paylodadEndPointSearchProperty(state, filterBasics).filtersOthers === 0 ?
                                                            'Más filtros...' :
                                                            `Más filtros (${paylodadEndPointSearchProperty(state, filterBasics).filtersOthers})`
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {
                                                state.loadingTable ?
                                                    <Col>
                                                        <div className="loader-box">
                                                            <div className="loader-9"></div>
                                                        </div>
                                                    </Col>
                                                    :
                                                    <>
                                                        {
                                                            state.data ?
                                                                <Col sm="12" className='mb-4 px-2'>
                                                                    <h6>Inmuebles encontrados: {state.data.length}</h6>
                                                                </Col>
                                                                :
                                                                null
                                                        }
                                                        <Col xs="12" className='px-0'>
                                                            {
                                                                state.data ?
                                                                    <>
                                                                        {
                                                                            state.data.length ?
                                                                                <Table responsive data-cy="table-results-for-day-0000">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: '10%' }}>Ref.</th>
                                                                                            <th scope="col" style={{ width: '15%' }}>Ocupado por</th>
                                                                                            <th scope="col" style={{ width: '40%' }}>Dirección</th>
                                                                                            <th className="d-none d-lg-table-cell" scope="col" style={{ width: '15%' }}>Estado</th>
                                                                                            <th className="d-none d-lg-table-cell" scope="col" style={{ width: '15%' }}>Días sin contactar</th>
                                                                                            <th scope="col" style={{ width: '5%' }}>Acciones</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            state.data.map((property, key) => {
                                                                                                return (
                                                                                                    <tr key={key} className={`${colorRowTableStatus(property.status)}`}>
                                                                                                        <td
                                                                                                            className='text-primary'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            title='Ver detalles'

                                                                                                        >
                                                                                                            <div
                                                                                                                data-cy="open-modal-view-details-0000"
                                                                                                                onClick={() => openModal(property, 'details', setState)}
                                                                                                            >
                                                                                                                {property.reference}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td className='small-font-td'>
                                                                                                            {property.occupied_by}
                                                                                                        </td>
                                                                                                        <td className='small-font-td'>
                                                                                                            {formAddress(property)}
                                                                                                        </td>
                                                                                                        <td className="d-none d-lg-table-cell">
                                                                                                            {property.status}
                                                                                                        </td >
                                                                                                        <td className="d-none d-lg-table-cell">
                                                                                                            {property.days_last_contact}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Button
                                                                                                                color="primary"
                                                                                                                className='col-12 px-3 w-auto'
                                                                                                                onClick={() => openModal(property, 'update', setState)}
                                                                                                                title="Editar inmueble"
                                                                                                                data-cy="open-modal-edit-0000"
                                                                                                            >
                                                                                                                <i className="fa fa-edit fa-lg mx-1" style={{ cursor: 'pointer' }} />
                                                                                                            </Button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </Table>

                                                                                :
                                                                                <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                                                                    <p>{thereAreNoAssociatedRecords}</p>
                                                                                </Alert>
                                                                        }
                                                                    </>
                                                                    :
                                                                    state.errorMessage ?
                                                                        <Alert color="secondary dark" data-cy="alert-there-are-not-records-0000">
                                                                            <p>{state.errorMessage}</p>
                                                                        </Alert>
                                                                        :
                                                                        null
                                                            }
                                                        </Col>
                                                    </>
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                    }
                </Row>
            </Container>
            <style>
                {
                    `
                    @media (max-width: 575px) {
                        .modal-dialog {
                            margin:0;                        
                        }
                        .modal-dialog-scrollable .modal-content {
                            max-height: 100vh
                        }
                        .modal-content {
                            height: 100vh
                        }
                    }
                    `
                }
            </style>
        </Fragment>
    )
}

export default PropertyDayZone