import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Table,
    Alert
} from 'reactstrap'
import { useSelector } from 'react-redux'

const Profile = () => {
    const user = useSelector(state => state.authState.profile);
        
    return (
        <Fragment>
            <Breadcrumb parent="Perfil usuario" title="Perfil usuario" />
            <Container fluid={true}>
                <Row>
                    <Col sm='12' className='px-0 px-sm-2'>
                        <Card className='card-table'>
                            <CardBody>
                                <Row className='justify-content-center'>
                                    <Col sm='12' className='text-center'>
                                        <img className='user-img-profile' src={user.picture} />
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col sm='12' md='6'>
                                        <div className="user-data text-center">
                                            <div className="title">{`${user.first_name} ${user.last_name}`}</div>
                                            <div className="position">{user.title}</div>
                                        </div>
                                    </Col>
                                    <Col className='mt-3 mt-md-0' sm='12' md='6'>
                                        <div className="email-profile text-center">
                                            <h6>
                                                <i className="fa fa-envelope mr-2 text-left" />
                                                Email
                                            </h6>
                                            <span>{user.email}</span>
                                        </div>
                                    </Col>
                                    <Col sm='12'>
                                        <hr />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default Profile;